import React from 'react'
import { RadioGroup } from '@mui/material'
import { makeStyles } from '@mui/styles'


const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'row',
  },
}))

export const CustomRadioGroup = ({ input, children, customClasses, ...rest }) => {
  const classes = useStyles()
  return (
    <RadioGroup
      className={`${classes.root} ${customClasses}`}
      {...input}
      {...rest}
      value={input.value}
      onChange={(event, value) => input.onChange(value)}
    >
      {children}
    </RadioGroup>
  )
}
