import React from 'react'
import * as router from '../../../services/router'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Add } from '@mui/icons-material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import { loadKeywords, keywordsOrder } from '../../../actions/keywordsActions'
import { TopSearch } from '../../header/TopSearch'
import { formatDate, sortBy } from '../../../utils/utils'
import Filters from './Filters'
import { CustomTableCell, makeSortableTableHeader } from '../../general/Table'
import { getOrder } from '../../../reducers/crud'
import { KEYWORDS_CATEGORIES, KEYWORDS_SUBCATEGORIES } from '../../../config/config'
import DataProviderFunctional from '../../loader/DataProviderFunctional'
import TextButton from '../../general/TextButton'
import { useTheme } from '@mui/material'

const headers = [
  { field: 'keyword', title: 'Keyword' },
  { field: 'subcategory', title: 'Subcategory' },
  { field: 'category', title: 'Category' },
  { field: 'updated_at', title: 'Last Edited' },
]

const ListTableHead = makeSortableTableHeader(headers)

const TableRows = ({ items, sortOrder, goToUrl, orderBy, order }) => {
  const itemsWithData = items.map((item) => {
    return {
      ...item,
    }
  })

  sortBy(itemsWithData, orderBy, order)

  return itemsWithData.map((item) => (
    <TableRow key={item.id} onClick={() => goToUrl(router.ROUTE_KEYWORD_EDIT + item.id)}>
      <CustomTableCell>{`${item.keyword}`}</CustomTableCell>
      <CustomTableCell>{`${
        KEYWORDS_SUBCATEGORIES[item.category].find((i) => i.value === item.subcategory).title
      }`}</CustomTableCell>
      <CustomTableCell>{`${KEYWORDS_CATEGORIES.find((i) => i.value === item.category).title}`}</CustomTableCell>
      <CustomTableCell>{formatDate(item.updated_at)}</CustomTableCell>
    </TableRow>
  ))
}

const KeywordsPresenter = () => {
  const theme = useTheme()
  const keywords = useSelector((state) => getKeywords(state))
  const { order, orderBy } = useSelector((state) => getOrder(state, 'keywords'))
  const dispatch = useDispatch()
  const goToUrl = (url) => dispatch(push(url))
  const orderFn = (orderBy) => () => dispatch(keywordsOrder(orderBy))

  return (
    <div className="exhibitions">
      <TopSearch title="Keywords" FiltersComponent={Filters}>
        <div className="item-right">
          <TextButton
            label="Add keyword"
            color="primary"
            startIcon={<Add  style={{ color: theme.palette.primary.main }} />}
            onClick={() => goToUrl(router.ROUTE_KEYWORD_CREATE)}
          />
        </div>
      </TopSearch>

      <Table className="non-fixed">
        <ListTableHead orderBy={orderBy} order={order} orderFn={orderFn} />
        <TableBody>
          <TableRows orderBy={orderBy} order={order} items={keywords} goToUrl={goToUrl} />
        </TableBody>
      </Table>
    </div>
  )
}

const getKeywords = (state) => {
  const keywords = Object.values(state.keywords.items)
  if (state.filters.visible) {
    //opposite fix
    return keywords
  } else {
    return keywords.filter((k) => {
      let searchable = `${k.keyword.toLowerCase()}`
      return searchable.includes(state.filters.q.toLowerCase())
    })
  }
}

const Keywords = () => {
  const loading = useSelector((state) => state.keywords.state)
  const error = useSelector((state) => state.keywords.error)
  const watch = 'page'
  const dispatch = useDispatch()
  const load = () => {
    dispatch(loadKeywords())
  }

  return (
    <DataProviderFunctional error={error} load={load} loading={loading} watch={watch} component={KeywordsPresenter} />
  )
}

export default Keywords
