import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { change, Field } from 'redux-form'
import { fieldStyle } from '../../form/style'
import { Tooltip, IconButton } from '@mui/material'
import { DOCUMENT_FORM } from '../../../services/forms'
import { Close } from '@mui/icons-material'
import { AutoComplete } from './AutoComplete'
import { COLOR_LIGHT_TEXT } from '../../../config/colors'

export const AddInstitutionsField = ({ member, index, fields }) => {
  const persons = useSelector((state) => state.provenanceInstitutions.items)
  const documentInstitutions = Object.values(persons).map((i) => ({
    id: i.id,
    name: `${i.name} (${i.founded})`,
  }))
  const dispatch = useDispatch()
  const changeFieldValue = (field, value) => dispatch(change(DOCUMENT_FORM, field, value))

  return (
    (<div key={index} className="flex-box select-reference artwork-row repeater-field">
      <div className="field">
        <Field
          //name={'recipient'}
          name={`${member}.id`}
          persons={documentInstitutions}
          changeFieldValue={changeFieldValue}
          {...fieldStyle}
          component={AutoComplete}
        />
      </div>
      <Tooltip title="Remove">
              <IconButton
                className="repeater-remove"
                onClick={() => fields.remove(index)}
                size="large">
                <Close style={{ color: COLOR_LIGHT_TEXT}} />
              </IconButton>
            </Tooltip>
    </div>)
  );
}
