import React from 'react'
import { useSelector } from 'react-redux'
import { IconButton } from '@mui/material'
import ArrowBack from '@mui/icons-material/ArrowBack'
import TextButton from './TextButton'

export const BackBtn = () => (
  <IconButton color="primary" onClick={() => window.history.back()} size="large">
    <ArrowBack />
  </IconButton>
)
export const EditTop = ({ title, remove }) => {
  const colorAccent = useSelector((state) => state.auth.colorAccent)

  return (
    <div className="flex-box top-title shadow-bottom">
      <div className="item-left" title="Back">
        <BackBtn />
      </div>
      <div className="title title--edit-top" style={{ color: colorAccent }}>
        {title}
      </div>

      <div className="item-right flex-box-centered">
        {remove && <TextButton label="Remove" onClick={remove} remove={true} />}
        {/* <LanguageSwitcher/> */}
      </div>
    </div>
  )
}
