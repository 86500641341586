import * as actionTypes from '../actions/actionTypes'
import {
  crudInitialState,
  itemLoad,
  itemLoaded,
  itemLoadFailed,
  itemSave,
  itemSaved,
  itemSaveFailed,
  itemsLoad,
  itemsLoaded,
  itemsLoadFailed,
} from './crud'

const initialState = crudInitialState

const actionHandlers = {
  [actionTypes.LITERATURES_LOAD]: itemsLoad,
  [actionTypes.LITERATURES_LOADED]: itemsLoaded,
  [actionTypes.LITERATURES_LOAD_FAILED]: itemsLoadFailed,
  [actionTypes.LITERATURE_LOAD]: itemLoad,
  [actionTypes.LITERATURE_LOADED]: itemLoaded,
  [actionTypes.LITERATURE_LOAD_FAILED]: itemLoadFailed,
  [actionTypes.LITERATURE_UPDATE]: itemSave,
  [actionTypes.LITERATURE_CREATE]: itemSave,
  [actionTypes.LITERATURE_UPDATED]: itemSaved,
  [actionTypes.LITERATURE_CREATED]: itemSaved,
  [actionTypes.LITERATURE_UPDATE_FAILED]: itemSaveFailed,
  [actionTypes.LITERATURE_CREATE_FAILED]: itemSaveFailed,
}

export default (state = initialState, { type, payload }) => {
  const handler = actionHandlers[type]
  return handler ? handler(state, payload) : state
}
