import * as actionTypes from '../actions/actionTypes'
import {
  //crudInitialState,
  itemLoad,
  itemLoaded,
  itemsLoadedAll,
  itemLoadFailed,
  itemSave,
  itemSavedCache,
  itemSaveFailed,
  itemsLoad,
  itemsLoaded,
  itemsLoadFailed,
  itemsChangeSortOrder,
  itemDelete,
  itemDeleted,
  itemDeleteFailed,
  itemsOrder,
} from './crud'
import { loadingState } from '../utils/loadingState'

const initialState = {
  items: {},
  item: {},
  pages: {},
  referenceErrors: [],
  page: 0,
  state: loadingState.IDLE,
  error: undefined,
  saveState: loadingState.IDLE,
  saveError: undefined,
  searchItems: [],
  searchError: undefined,
  searchState: loadingState.IDLE,
  deleteState: loadingState.IDLE,
  deleteError: undefined,
  sortOrder: 'mostRecent',
  orderBy: 'created_at',
  order: 'desc',
}

const actionHandlers = {
  [actionTypes.PERSONS_LOAD]: itemsLoad,
  [actionTypes.PERSONS_LOADED]: itemsLoaded,
  [actionTypes.PERSONS_LOADED_ALL]: itemsLoadedAll,
  [actionTypes.PERSONS_LOAD_FAILED]: itemsLoadFailed,
  [actionTypes.PERSON_LOAD]: itemLoad,
  [actionTypes.PERSON_LOADED]: itemLoaded,
  [actionTypes.PERSON_LOAD_FAILED]: itemLoadFailed,
  [actionTypes.PERSON_UPDATE]: itemSave,
  [actionTypes.PERSON_CREATE]: itemSave,
  [actionTypes.PERSON_UPDATED]: itemSavedCache,
  [actionTypes.PERSON_CREATED]: itemSavedCache,
  [actionTypes.PERSON_UPDATE_FAILED]: itemSaveFailed,
  [actionTypes.PERSON_CREATE_FAILED]: itemSaveFailed,
  [actionTypes.PERSONS_CHANGE_SORT_ORDER]: itemsChangeSortOrder,
  [actionTypes.PERSONS_ORDER]: itemsOrder,
  [actionTypes.PERSON_DELETE]: itemDelete,
  [actionTypes.PERSON_DELETED]: itemDeleted,
  [actionTypes.PERSON_DELETE_FAILED]: itemDeleteFailed,
}

export default (state = initialState, { type, payload }) => {
  const handler = actionHandlers[type]
  return handler ? handler(state, payload) : state
}
