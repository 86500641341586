import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Field, FieldArray, formValueSelector } from 'redux-form'
import { documentsError, documentsErrorRemove, searchDocuments } from '../../actions/documentsActions'
import { DOCUMENT_SUBTYPES, DOCUMENT_TYPES } from '../../config/config'
import { loadingState } from '../../utils/loadingState'
import {
  containsByReferenceId,
  documentAuthorsString,
  getTitleByValue,
  getTitleByValueInObject,
  or,
} from '../../utils/utils'
import OpenClose from '../general/OpenClose'
import { Close, Search } from '@mui/icons-material'
import { CustomTextField } from '../general/CustomTextField'
import TextButton from '../general/TextButton'
import { COLOR_BLACK, COLOR_GREY, COLOR_LIGHT_TEXT } from '../../config/colors'
import { IconButton } from '@mui/material'

const DocumentsReferencesSearch = ({ name, formType, onSelect }) => {
  const items = useSelector((state) => state.documents.searchItems)
  const documentsReferences = useSelector((state) => or(formValueSelector(formType)(state, name), []))
  const searchState = useSelector((state) => state.documents.searchState)
  const dispatch = useDispatch()
  const search = (query) => dispatch(searchDocuments(query))
  return (
    <div style={{ width: '100%' }}>
      <div className="field-with-icon">
        <CustomTextField
          id="archive-reference-search-input"
          width="full"
          light="true"
          label="Search"
          hideLabel={true}
          type="text"
          onChange={(_, query) => search(query)}
        />
        <Search className="search-icon" style={{ color: COLOR_BLACK }} />
      </div>
      {searchState === loadingState.IDLE ? null : searchState === loadingState.LOADING ? (
        <span>Searching...</span>
      ) : items.length === 0 ? (
        <span>No results</span>
      ) : (
        <div className="scrollable">
          <table className="non-fixed" cellSpacing={0}>
            <tbody>
              <tr>
                <th>Author</th>
                <th>Recipient</th>
                <th>Date</th>
                <th>Title</th>
                <th>Document type</th>
                <th>Number of pages</th>
                <th />
              </tr>
              {items.map((item, i) => (
                <OpenClose key={`archive-reference-search-modal-${i}`}>
                  {({ isOpen, open, close }) => (
                    <React.Fragment>
                      <tr
                        key={i}
                        className={(i + 1) % 2 === 0 ? 'odd edit-toggle' : 'even edit-toggle'}
                        onClick={isOpen ? close : open}
                      >
                        <td>{documentAuthorsString(item.documents_authors, item.documents_authors_institutions)}</td>
                        <td>
                          {documentAuthorsString(item.documents_recipients, item.documents_recipients_institutions)}
                        </td>
                        <td>{item.date}</td>
                        <td>{item.title}</td>
                        <td>{item.subtype ? getTitleByValueInObject(item.subtype, DOCUMENT_SUBTYPES) : ''}</td>
                        <td>{item.pages}</td>
                        <td style={{ width: '200px' }} />
                      </tr>
                      {isOpen ? (
                        <tr className={(i + 1) % 2 === 0 ? 'odd edit-open' : 'even edit-open'}>
                          <td colSpan={4}>
                            <div className=" flex-box-centered ref-comments">
                              <div className="field-label" style={{ width: '90px' }}>
                                Page:
                              </div>
                              <Field
                                id={'name_temp_document' + '[' + i + '].page-input'}
                                name={'name_temp_document' + '[' + i + '].page'}
                                width="short"
                                light={true}
                                component={CustomTextField}
                                label="Page:"
                                hideLabel={true}
                                onChange={(e) => {
                                  item.page = e.target.value
                                }}
                              />
                            </div>
                            <div className=" flex-box-centered ref-comments">
                              <div className="field-label" style={{ width: '90px' }}>
                                Comments:
                              </div>
                              <Field
                                id={'name_temp_document' + '[' + i + '].comment-input'}
                                name={'name_temp_document' + '[' + i + '].comment'}
                                className="flex-full"
                                component={CustomTextField}
                                light={true}
                                label="Comments:"
                                hideLabel={true}
                                onChange={(e) => {
                                  item.comment = e.target.value
                                }}
                              />
                            </div>
                          </td>
                          <td style={{ width: '200px', textAlign: 'center' }}>
                            <TextButton
                              disabled={containsByReferenceId(documentsReferences, item.id)}
                              color="primary"
                              onClick={() => {
                                onSelect(item)
                                close()
                              }}
                              type="button"
                              label="Add Reference"
                            />
                          </td>
                        </tr>
                      ) : null}
                    </React.Fragment>
                  )}
                </OpenClose>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

const renderHeader = () => (
  <tr>
    <th>Author</th>
    <th>Date</th>
    <th>Title</th>
    <th>Document type</th>
    <th>Pages</th>
    <th />
  </tr>
)

//Used for Existing References:
const ArchiveReferencesListItem = ({ name, onRemove, item, index }) => (
  <OpenClose defaultValue={item ? item.page : undefined}>
    {({ isOpen, open, close }) => (
      <React.Fragment>
        <tr key={index} className={isOpen ? 'open-row' : (index + 1) % 2 === 0 ? 'odd' : 'even'}>
          <td>{documentAuthorsString(item.documents_authors, item.documents_authors_institutions)}</td>
          <td>{item.date}</td>
          <td>{item.title}</td>
          <td>{item.type ? getTitleByValue(item.type, DOCUMENT_TYPES) : ''}</td>
          <td>{item.page ? item.page : ''}</td>
          <td>
            <div className="flex-box-centered">
              {isOpen ? (
                <TextButton
                  color="primary"
                  onClick={() => {
                    close()
                  }}
                  type="button"
                  label="Save"
                />
              ) : (
                <TextButton color="primary" onClick={open} type="button" label="Edit" />
              )}
              <IconButton
                className="repeater-remove"
                onClick={() => {
                  onRemove()
                }}
                size="large">
                <Close style={{ color: COLOR_LIGHT_TEXT }} />
              </IconButton>
            </div>
          </td>
        </tr>
        {item.comment ? (
          <tr className={isOpen ? 'open-row' : (index + 1) % 2 === 0 ? 'odd' : 'even'}>
            <td colSpan={6} className="tr-comment">
              {item.comment}
            </td>
          </tr>
        ) : null}
        {isOpen ? (
          <tr className={isOpen ? 'open-row' : ''}>
            <td colSpan={6}>
              <div>
                <div className=" flex-box-centered ref-comments">
                  <div className="field-label" style={{ width: '140px' }}>
                    Page:
                  </div>
                  <Field
                    id={name + '.page-input'}
                    name={name + '.page'}
                    width="short"
                    light={true}
                    component={CustomTextField}
                    label="Page:"
                    hideLabel={true}
                  />
                  {/* <div className="field-label" style={{ width: '140px', margin: '0 16px' }}>
                    Catalogue number:
                  </div>
                  <Field name={name + '.catalogue'} {...whiteFieldStyle} component={TextField} /> */}
                </div>
                <div className=" flex-box-centered ref-comments">
                  <div className="field-label" style={{ width: '140px' }}>
                    Comments:
                  </div>
                  <Field
                    id={name + '.comment-input'}
                    name={name + '.comment'}
                    className="flex-full"
                    component={CustomTextField}
                    light={true}
                    label="Comments:"
                    hideLabel={true}
                  />
                </div>
              </div>
            </td>
          </tr>
        ) : null}
      </React.Fragment>
    )}
  </OpenClose>
)

const ArchiveReferencesList = ({ fields, formType }) => {
  const dispatch = useDispatch()
  const showError = (value) => dispatch(documentsError(value))
  const removeError = (value) => dispatch(documentsErrorRemove(value))
  const colorAccent = useSelector((state) => state.auth.colorAccent)
  return (
    <OpenClose>
      {({ isOpen, open, close }) => (
        <div className="ref-tabs">
          <span
            className={`${!isOpen ? 'tab active' : 'tab'} modal-header`}
            style={{ borderBottomColor: !isOpen ? colorAccent : COLOR_GREY }}
            onClick={() => close()}
          >
            Add References
          </span>
          {!isOpen && (
            <DocumentsReferencesSearch
              name={fields.name}
              formType={formType}
              onSelect={(x) => {
                fields.push({ ...x, reference_id: x.id })
              }}
            />
          )}
          <span
            href="#"
            className={`${isOpen ? 'tab active' : 'tab'} modal-header`}
            style={{ borderBottomColor: isOpen ? colorAccent : COLOR_GREY }}
            onClick={() => open()}
          >
            Existing References
          </span>
          {isOpen && (
            <table className="non-fixed existing-refs" cellSpacing={0}>
              <tbody>
                {renderHeader()}
                {fields.map((name, index) => (
                  <ArchiveReferencesListItem
                    key={index}
                    index={index}
                    name={name}
                    showError={showError}
                    removeError={removeError}
                    onRemove={() => fields.remove(index)}
                    item={fields.get(index)}
                  />
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </OpenClose>
  )
}

export const DocumentsReferencesForm = ({ name, formType, close }) => (
  <div>
    <FieldArray name={name} formType={formType} component={ArchiveReferencesList} />
    <div className="right-controls">
      <TextButton
        color="primary"
        onClick={() => {
          close()
        }}
        type="button"
        label="Done"
      />
    </div>
  </div>
)
