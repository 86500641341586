import React from 'react'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import TableCell from '@mui/material/TableCell'
import { styled } from '@mui/styles'

export const CustomTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    color: 'rgb(158, 158, 158)',
  },
  '&.MuiTableCell-body': {
    color: theme.palette.common.white,
  },
}))

export const makeSortableTableHeader =
  (headers) =>
  ({ orderBy, order, orderFn }) =>
    (
      <TableHead>
        <TableRow>
          {headers.map((header, i) => (
            <CustomTableCell key={i}>
              <TableSortLabel
                active={orderBy === header.field}
                direction={order}
                onClick={() => orderFn(header.field)} 
              >
                {header.title}
              </TableSortLabel>
            </CustomTableCell>
          ))}
        </TableRow>
      </TableHead>
    )
