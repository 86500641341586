import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, FieldArray, getFormValues } from 'redux-form'
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography, Tooltip, IconButton } from '@mui/material'
import { Close, Delete, ExpandMore } from '@mui/icons-material'
import { Uploader } from '../../form/Uploader'
import OpenClose from '../../general/OpenClose'
import Modal from 'react-modal'
import { COLOR_LIGHT_TEXT } from '../../../config/colors'
import { accordionStyle } from '../../form/style'
import { WYSIWYGEditor } from '../../editor/Editor'
import { DOCUMENT_FORM } from '../../../services/forms'
import { deleteDocumentPage } from '../../../actions/documentsActions'
import OutlinedButton from '../../general/OutlinedButton'
import { CustomRefreshIndicator } from '../../general/CustomRefreshIndicator'
import { CustomTextField } from '../../general/CustomTextField'
import { CustomTextArea } from '../../general/CustomTextArea'

const createImage = (image, type) => ({ ...image, type })

const Image = ({ fields, upload, image, onUpload, onRemove, title, lang }) => {
  return image === null || image === undefined || image.url === undefined || image.url === null ? (
    <div className="image-container">
      <Uploader
        style={{ margin: '0 auto' }}
        upload={upload}
        onSuccess={onUpload}
        loader={CustomRefreshIndicator}
        onStart={console.log}
        onError={console.log}
      />
    </div>
  ) : (
    <OpenClose>
      {({ isOpen, open, close }) => (
        <div className="image-container">
          <div className="img-holder">
            <img src={image.thumb_url} alt="" />
            <div className="hover-background" onClick={open}></div>
          </div>

          {isOpen && (
            <Modal
              isOpen={isOpen}
              onRequestClose={close}
              className={{
                base: 'modal',
                afterOpen: 'modal-open reference-modal photo-modal',
                beforeClose: 'modal-close',
              }}
              overlayClassName={{
                base: 'modal-overlay',
                afterOpen: 'modal-overlay-open',
                beforeClose: 'modal-overlay-close',
              }}
            >
              <ImagePreview
                upload={upload}
                title={title}
                image={image}
                close={close}
                member={fields && image ? fields.getAll().findIndex((x) => x.id === image.id) : null}
                lang={lang}
                onRemove={onRemove}
              />
            </Modal>
          )}
        </div>
      )}
    </OpenClose>
  )
}

const ImagePreview = ({ title, image, close, member, lang, onRemove }) => {
  return (
    (<div className="image-preview">
      <div className="left-right-block flex-box-centered">
        <div className="image-label">{title}</div>
        <div className="right-controls flex-box-centered">
          <Tooltip title="Remove">
            <IconButton
              onClick={onRemove}
              color="background.default"
              style={{
                padding: 0,
                marginLeft: '12px',
                marginRight: '12px',
              }}
              size="large">
              <Delete />
            </IconButton>
          </Tooltip>
          <Tooltip title="Close">
            <IconButton
              onClick={close}
              color="background.default"
              style={{
                padding: 0,
              }}
              tooltip="Close"
              size="large">
              <Close />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <img src={image.thumb_url} alt="" />
    </div>)
  );
}

const Page = ({ upload, member, index, fields, lang, changeFieldValue }) => {
  const imageFieldName = `${member}.image`
  const form = useSelector((state) => getFormValues(DOCUMENT_FORM)(state))
  const image = form.documents_pages[index].image
  const dispatch = useDispatch()

  return (
    (<Accordion {...accordionStyle}>
      <AccordionSummary expandIcon={<ExpandMore style={{ color: COLOR_LIGHT_TEXT }} />}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography color="textPrimary" className="align-left-label ">
            {`Page: ${fields.get(index).page_number ? fields.get(index).page_number : ''}`}
          </Typography>
          <Tooltip title="Remove">
            <IconButton
              className="repeater-remove"
              style={{ color: COLOR_LIGHT_TEXT }}
              onClick={(e) => {
                e.stopPropagation()
                if (window.confirm('Are you sure?')) {
                  dispatch(deleteDocumentPage(fields.get(index).id))
                  fields.remove(index)
                }
              }}
              tooltip="remove"
              size="large">
              <Close />
            </IconButton>
          </Tooltip>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <div className="page">
          <div className="flex-box">
            <Image
              image={image}
              fields={fields}
              upload={upload}
              onUpload={(x) => {
                const img = createImage(x, 'page')
                changeFieldValue(imageFieldName, img)
              }}
              onRemove={() => changeFieldValue(imageFieldName, null)}
            />
            <div>
              <div className="field field--top">
                <Field
                  id={`${member}.page_number-${index}-input`}
                  name={`${member}.page_number`}
                  component={CustomTextField}
                  label="Page Number"
                />
              </div>
              <div className="field">
                <Field
                  id={`${member}.photo_credit-input`}
                  name={`${member}.photo_credit`}
                  component={CustomTextArea}
                  label="Photo credit"
                  minRows={2}
                  maxRows={4}
                  type="text"
                />
              </div>
            </div>
            <div className="remove-container"></div>
          </div>
          <div className="flex-box">
            <div>
              <div className="field">
                <div className="field-label">Transcription</div>
                <div className="editor--transcription">
                  <Field name={`${member}.transcription`} component={WYSIWYGEditor} />
                  {/* <Field name={`${member}.transcription`} component={WYSIWYGEditor} readOnly/> */}
                </div>
              </div>
              <div className="field">
                <div className="field-label">Translation</div>
                <div className="editor--transcription">
                  <Field name={`${member}.translation`} component={WYSIWYGEditor} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>)
  );
}

const Pages = ({ upload, fields, lang, changeFieldValue }) => (
  <div>
    <div className="">
      <div className="">
        {fields.map((member, index, fields) => {
          return (
            <Page
              key={index}
              upload={upload}
              member={member}
              index={index}
              fields={fields}
              lang={lang}
              changeFieldValue={changeFieldValue}
            />
          )
        })}
        <div style={{ margin: '10px' }}>
          <OutlinedButton color="secondary" label="Add new page" onClick={() => fields.push({ type: 'page' })} />
        </div>
      </div>
    </div>
  </div>
)

const DocumentPages = ({ upload, lang, changeFieldValue }) => {
  return (
    <>
      <h1>Pages (Images): </h1>
      <div className="row">
        <FieldArray
          name="documents_pages"
          component={Pages}
          upload={upload}
          lang={lang}
          changeFieldValue={changeFieldValue}
        />
      </div>
    </>
  )
}

export default DocumentPages
