import React from 'react'
import Button from '@mui/material/Button'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'

const useStyles = makeStyles((theme) => ({
  customButton: {
    '&:hover': {
      backgroundColor: 'rgba(153, 153, 153, 0.2)',
    },
    borderColor: theme.palette.secondary.main,
    borderRadius: '2px',
    padding: '6px 16px',
    minWidth: 88,
  },
  labelStyle: {
    fontSize: '14px',
    fontWeight: '500',
  },
}))

const OutlinedButton = ({ label, type, fullWidth, color, startIcon, endIcon, onClick, isRecent, onMouseDown, customClasses }) => {
  const classes = useStyles()

  return (
    <Button
      variant="outlined"
      type={type}
      fullWidth={fullWidth}
      color={color}
      className={`${classes.customButton} ${customClasses}`}
      onClick={onClick}
      onMouseDown={onMouseDown}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      <Typography className={classes.labelStyle}>{label}</Typography>
    </Button>
  )
}

export default OutlinedButton
