import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, FieldArray } from 'redux-form'
import { IconButton, Tooltip, MenuItem } from '@mui/material'
import Modal from 'react-modal'
import { Close, Search } from '@mui/icons-material'
import OpenClose from '../../general/OpenClose'
import { setFilter } from '../../../actions/filtersActions'
import TextButton from '../../general/TextButton'
import OutlinedButton from '../../general/OutlinedButton'
import { COLOR_BLACK, COLOR_LIGHT_TEXT } from '../../../config/colors'
import { CustomSelect } from '../../general/CustomSelect'
import { CustomTextField } from '../../general/CustomTextField'

const CurratorComponent = ({ index, fields, member, persons }) => {
  return (
    (<div className="flex-box artwork-row">
      <div className="repeater-field">
        <div className="flex-box-centered">
          <Field name={`${member}.id`} component={CustomSelect}>
            {Object.keys(persons).map((key) => {
              return (
                <MenuItem key={key} value={persons[key].id}>
                  {persons[key].firstname + ' ' + persons[key].lastname}
                </MenuItem>
              )
            })}
          </Field>
          <Tooltip title="Remove">
            <IconButton
              className="repeater-remove"
              onClick={() => fields.remove(index)}
              size="large">
              <Close style={{ color: COLOR_LIGHT_TEXT }} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>)
  );
}

const ExhibitionCurratorsField = ({ fields, persons }) => {
  const currator = useSelector((state) => state.filters.currator)
  const dispatch = useDispatch()
  const handleSetFilter = (type, value) => {
    dispatch(setFilter(type, value))
  }

  return (
    <div>
      <div className="field-label">Currator</div>
      {fields && fields.length > 0 ? (
        fields.map((member, index, fields) => (
          <CurratorComponent key={index} member={member} index={index} fields={fields} persons={persons} />
        ))
      ) : (
        <div className="empty-list">Unknown</div>
      )}
      <OpenClose>
        {({ isOpen, open, close }) => (
          <div>
            <OutlinedButton color="secondary" onClick={open} label="Add new currator" />
            <Modal
              isOpen={isOpen}
              onRequestClose={close}
              className={{
                base: 'modal',
                afterOpen: 'modal-open reference-modal searchable-modal',
                beforeClose: 'modal-close',
              }}
              overlayClassName={{
                base: 'modal-overlay',
                afterOpen: 'modal-overlay-open',
                beforeClose: 'modal-overlay-close',
              }}
            >
              <h4 className="modal-header">Add currator</h4>
              <div className="">
                <CustomTextField
                  id="currator-search-input"
                  width="full"
                  light="true"
                  label="Search currator"
                  type="text"
                  value={currator}
                  onChange={(_, value) => handleSetFilter('currator', value)}
                />
                <Search className="search-icon" style={{ color: COLOR_BLACK }} />
              </div>
              <div className="scrollable">
                <table className="non-fixed" cellSpacing={0}>
                  <tbody>
                    <tr>
                      <th>First name</th>
                      <th>Last name</th>
                    </tr>
                    {Object.keys(persons)
                      //.filter((key, i) => fields.length && !fields.getAll().some(f => f.id === persons[key].id))
                      .filter((key, i) => {
                        if (!fields.length) {
                          return true
                        } else {
                          return !fields.getAll().some((f) => f.id === persons[key].id)
                        }
                      })
                      .filter(
                        (key, i) =>
                          persons[key].firstname.toLowerCase().includes(currator) ||
                          persons[key].lastname.toLowerCase().includes(currator)
                      )
                      .map((key, index) => (
                        <tr
                          key={key}
                          className={index % 2 === 0 ? 'even' : 'odd'}
                          onClick={() => {
                            fields.push(persons[key])
                            close()
                          }}
                        >
                          <td>{persons[key].firstname}</td>
                          <td>{persons[key].lastname}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="right-controls save-button">
                <TextButton color="primary" label="Done" onClick={() => close()} type="button" />
              </div>
            </Modal>
          </div>
        )}
      </OpenClose>
    </div>
  )
}

const ExhibitionCurrators = ({ persons }) => (
  <FieldArray name="currators" component={ExhibitionCurratorsField} persons={persons} />
)

export default ExhibitionCurrators
