import React from 'react'
import Select from 'react-select'
import { CustomDropdownIndicator } from '../../general/CustomDropdownIndicator'

export const AutoComplete = ({ input, changeFieldValue, items }) => {
  return (
    <Select
      className="react-select-container"
      classNamePrefix="react-select"
      name={input.name}
      options={items}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      value={items.find((item) => item.id === input.value)}
      onChange={(selectedOption) => changeFieldValue(input.name, selectedOption?.id)}
      isClearable={false}
      isSearchable={true}
      components={{ DropdownIndicator: CustomDropdownIndicator }}
    />
  )
}
