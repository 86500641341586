import * as actionTypes from '../actions/actionTypes'
import {
    crudInitialState,
    itemLoad,
    itemLoaded,
    itemLoadFailed,
    itemSave,
    itemSaveFailed,
    itemsLoad,
    itemsLoaded,
    itemsLoadFailed,
    itemDelete,
    itemDeleted,
    itemDeleteFailed,
    itemsLoadedAll,
    itemSavedCache,
    itemsChangeSortOrder, itemsOrder, itemsLoadedBasic
} from './crud'

const initialState = crudInitialState

const actionHandlers = {
  [actionTypes.EXHIBITIONS_LOAD]: itemsLoad,
  [actionTypes.EXHIBITIONS_LOADED]: itemsLoaded,
  [actionTypes.EXHIBITIONS_LOADED_ALL]: itemsLoadedAll,
  [actionTypes.EXHIBITIONS_LOAD_FAILED]: itemsLoadFailed,
  [actionTypes.EXHIBITIONS_BASIC_LOAD]: itemsLoad,
  [actionTypes.EXHIBITIONS_BASIC_LOADED]: itemsLoaded,
  [actionTypes.EXHIBITIONS_BASIC_LOADED_ALL]: itemsLoadedBasic,
  [actionTypes.EXHIBITIONS_BASIC_LOAD_FAILED]: itemsLoadFailed,
  [actionTypes.EXHIBITION_LOAD]: itemLoad,
  [actionTypes.EXHIBITION_LOADED]: itemLoaded,
  [actionTypes.EXHIBITION_LOAD_FAILED]: itemLoadFailed,
  [actionTypes.EXHIBITION_UPDATE]: itemSave,
  [actionTypes.EXHIBITION_CREATE]: itemSave,
  [actionTypes.EXHIBITION_UPDATED]: itemSavedCache,
  [actionTypes.EXHIBITION_CREATED]: itemSavedCache,
  [actionTypes.EXHIBITION_UPDATE_FAILED]: itemSaveFailed,
  [actionTypes.EXHIBITION_CREATE_FAILED]: itemSaveFailed,
  [actionTypes.EXHIBITION_DELETE]: itemDelete,
  [actionTypes.EXHIBITION_DELETED]: itemDeleted,
  [actionTypes.EXHIBITION_DELETE_FAILED]: itemDeleteFailed,
  [actionTypes.EXHIBITIONS_CHANGE_SORT_ORDER]: itemsChangeSortOrder,
  [actionTypes.EXHIBITIONS_ORDER]: itemsOrder
}

export default (state = initialState, { type, payload }) => {
  const handler = actionHandlers[type] 
  return handler ? handler(state, payload) : state
}