import React, { useRef, useState } from 'react'
import { EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useSelector } from 'react-redux'
import IconComment from '@mui/icons-material/Comment'
import { DoubleUnderline } from './DoubleUnderline'
import { InsertMention } from './InsertMention'
import { InsertComment } from './InsertComment'
import { getArtworkImage, getArtworkTitle, getArtworkTitleFromTranslationsArr } from '../../utils/utils'
import { COLOR_EDITOR_SELECTED_BCG, COLOR_GRAY_BCG } from '../../config/colors'

export const WYSIWYGEditor = ({ input, readOnly = false }) => {
  const [showCommentInput, setShowCommentInput] = useState(false)
  const editor = useRef(null)
  const artworks = useSelector((state) => state.artworks.items)
  const exhibitions = useSelector((state) => state.exhibitions.items)
  const institutions = useSelector((state) => state.provenanceInstitutions.items)
  const persons = useSelector((state) => state.provenancePersons.items)
  const documentPersons = Object.values(persons).map((i) => ({
    id: i.id,
    name: `${i.firstname} ${i.lastname}`,
    label: `${i.firstname} ${i.lastname} (b. ${i.birth_year})`,
    type: 'person',
  }))
  const documentExhibitions = Object.values(exhibitions).map((i) => ({
    id: i.id,
    title: i.title,
    location: i.location,
    from_year: i.from_year,
    label: `${i.title} - ${i.location} (${i.from_year})`,
    type: 'exhibition',
  }))
  const documentInstitutions = Object.values(institutions).map((i) => ({
    id: i.id,
    title: i.name,
    label: i.name,
    type: 'institution',
  }))
  const documentArtworks = Object.values(artworks).map((i) => {
    // use stable_id for Artworks instead if id.
    return {
      id: i.stable_id,
      title: getArtworkTitleFromTranslationsArr(i) || getArtworkTitle(i),
      image: getArtworkImage(i),
      label: `${getArtworkTitleFromTranslationsArr(i) || getArtworkTitle(i)} ${
        i.nr_loge_k ? '(' + i.nr_loge_k + ')' : ''
      }`,
      type: 'artwork',
    }
  })

  const onCommentClick = (e) => {
    e.preventDefault()
    setShowCommentInput(true)
  }

  const decorator = [
    { strategy: findMentions, component: MentionComponent },
    {
      strategy: findComments,
      component: CommentComponent,
      props: { clickComment: onCommentClick },
    },
  ]

  return (
    <>
      <Editor
        readOnly={readOnly}
        toolbarHidden={readOnly}
        ref={editor}
        editorState={input.value ? input.value : EditorState.createEmpty()}
        toolbarClassName="editor__toolbar"
        wrapperClassName="editor__wrapper"
        editorClassName="editor__editor"
        onEditorStateChange={input.onChange}
        customStyleMap={styleMap}
        customDecorators={decorator}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'fontFamily',
            'list',
            'textAlign',
            'colorPicker',
            'link',
            //'embedded',
            //'emoji',
            //'image',
            //'remove',
            'history',
          ],
          list: { options: ['unordered', 'ordered'] },
          textAlign: {
            inDropdown: true,
          },
        }}
        toolbarCustomButtons={[
          <DoubleUnderline />,
          <InsertMention items={documentPersons} title="person" labelKey="label" />,
          <InsertMention items={documentExhibitions} title="exhibition" labelKey="label" />,
          <InsertMention items={documentInstitutions} title="institution" labelKey="label" />,
          <InsertMention items={documentArtworks} title="artwork" labelKey="label" />,
          <InsertComment
            title="comment"
            labelKey="label"
            showCommentInput={showCommentInput}
            setShowCommentInput={setShowCommentInput}
          />,
        ]}
      />
    </>
  )
}

const MentionComponent = (props) => {
  const { type, id, name, label } = props.contentState.getEntity(props.entityKey).getData()
  return (
    <span data-type={type} data-id={id} title={label} className="editor__mention">
      {props.children}
    </span>
  )
}

const CommentComponent = React.memo((props) => {
  const { clickComment, children, contentState, entityKey } = props
  const content = contentState.getEntity(entityKey)?.getData() || {}
  return (
    <span onClick={clickComment}>
      <span className="editor__icon">
        <IconComment />
      </span>
      <span className="editor__commented" data-type="comment" data-content={content ? content.value : ''}>
        {children}
      </span>
    </span>
  )
})

export const findMentions = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity()
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'MENTION'
  }, callback)
}

export const findComments = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity()
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'COMMENT'
  }, callback)
}

export const styleMap = {
  SELECTED: {
    color: COLOR_GRAY_BCG,
    background: COLOR_EDITOR_SELECTED_BCG,
  },
  DOUBLE_UNDERLINE: {
    textDecoration: 'underline',
    textDecorationStyle: 'double',
  },
}
