import React, { useRef } from 'react'
import { Field, FieldArray } from 'redux-form'
import OpenClose from '../general/OpenClose'
import { IconButton, Tooltip } from '@mui/material'
import { AddCircleOutline, Close, Delete } from '@mui/icons-material'
import Modal from 'react-modal'
import { getHotspotCoords } from '../../utils/utils'
import { CustomTextField } from '../general/CustomTextField'
import { CustomTextArea } from '../general/CustomTextArea'

const renderHotspots = ({ fields, image, lang }) => {
  const hotspotRef = useRef(false)

  return (
    (<div className="hotspots">
      <img
        src={image.thumb_url}
        ref={hotspotRef}
        onClick={(e) => {
          fields.push(getHotspotCoords(e, hotspotRef))
        }}
        alt={''}
      />
      <ul className="row">
        {fields.map((member, index) => (
          <li key={index}>
            <Field
              id={`${member}.title-input`}
              name={`${member}.title`}
              type="text"
              component={CustomTextField}
              placeholder="Title NB"
              label="Title NB"
              hideLabel={true}
            />
            <Field
              id={`${member}.title_en-input`}
              name={`${member}.title_en`}
              type="text"
              component={CustomTextField}
              placeholder="Title EN"
              label="Title EN"
              hideLabel={true}
            />
            <Field
            id={`${member}.description-input`}
              name={`${member}.description`}
              component={CustomTextArea}
              placeholder="Description NB"
              label="Description NB"
              hideLabel={true}
              type="text"
              minRows={3}
              maxRows={10}
            />
            <Field
              name={`${member}.description_en`}
              component={CustomTextArea}
              placeholder="Description EN"
              label="Description EN"
              hideLabel={true}
              type="text"
              minRows={3}
              maxRows={10}
            />
            <Field
              name={`${member}.top`}
              component={CustomTextField}
              style={{ visibility: 'hidden', width: 0, height: 0, position: 'relative', overflow: 'hidden' }}
              type="hidden"
            />
            <Field
              name={`${member}.left`}
              component={CustomTextField}
              style={{ visibility: 'hidden', width: 0, height: 0, position: 'relative', overflow: 'hidden' }}
              type="hidden"
            />
            <Tooltip title="Remove">
              <IconButton
                onClick={() => fields.remove(index)}
                color="secondary"
                style={{
                  padding: 0,
                  margin: 0,
                  position: 'absolute',
                  right: 0,
                  bottom: '15px',
                }}
                size="large">
                <Delete />
              </IconButton>
            </Tooltip>
          </li>
        ))}
      </ul>
    </div>)
  );
}

export const PhotoHotSpots = ({ image, member, lang }) => {
  return (
    (<OpenClose>
      {({ isOpen, open, close }) => (
        <div>
          {!isOpen && (
            <Tooltip title="Add hotspot">
              <IconButton
                onClick={open}
                className="iconbutton"
                style={{
                  padding: 0,
                  marginTop: 0,
                  marginRight: '12px',
                }}
                size="large">
                <AddCircleOutline />
              </IconButton>
            </Tooltip>
          )}
          {isOpen && (
            <Modal
              isOpen={isOpen}
              onRequestClose={close}
              className={{
                base: 'modal',
                afterOpen: 'modal-open reference-modal',
                beforeClose: 'modal-close',
              }}
              overlayClassName={{
                base: 'modal-overlay',
                afterOpen: 'modal-overlay-open',
                beforeClose: 'modal-overlay-close',
              }}
            >
              <div className="hotspots-select">
                <Tooltip title="Close">
                  <IconButton
                    onClick={close}
                    className="close"
                    color="secondary"
                    style={{
                      padding: 0,
                      position: 'absolute',
                    }}
                    size="large">
                    <Close />
                  </IconButton>
                </Tooltip>
                <p>Select target point for the hotspot on the image</p>

                <FieldArray name={`${member}.hotspots`} component={renderHotspots} image={image} lang={lang} />
              </div>
            </Modal>
          )}
        </div>
      )}
    </OpenClose>)
  );
}
