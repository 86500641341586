import React, { memo } from 'react'
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api'
import { GOOGLE_MAPS_KEY } from '../../config/config'
import Geosuggest from '@ubilabs/react-geosuggest'
import './MapField.css'
import { useRef } from 'react'

const GOOGLE_MAP_LIBRARIES = ['places']
const fixtures = [
  { label: 'Oslo', location: { lat: 59.913868, lng: 10.752245 } },
  { label: 'Bergen', location: { lat: 60.39299, lng: 5.32415 } },
  { label: 'Stavanger', location: { lat: 58.969975, lng: 5.733107 } },
]

const Map = memo((props) => (
  <div style={{ height: '300px', width: '100%' }}>
    <GoogleMap
      onClick={(a) => {
        props.changeFieldValue('latitude', a.latLng.lat())
        props.changeFieldValue('longitude', a.latLng.lng())
      }}
      zoom={20}
      center={{
        lat: props.latitude.input.value || 0,
        lng: props.longitude.input.value || 0,
      }}
      mapContainerStyle={{ height: '100%', width: '100%' }}
    >
      <Marker
        position={{
          lat: props.latitude.input.value || 0,
          lng: props.longitude.input.value || 0,
        }}
      />
    </GoogleMap>
  </div>
))

// const WithMap = withGoogleMap(Map)

const Script = (props) => {
  const geosuggestEl = useRef(null)

  const onSuggestSelect = (select) => {
    if (select && select.location) {
      props.changeFieldValue('latitude', select.location.lat)
      props.changeFieldValue('longitude', select.location.lng)
    }
  }
  return (
    <div>
      <label className="field-label label--basic">Location on map</label>

      <Geosuggest
        ref={geosuggestEl}
        placeholder="Search places"
        fixtures={fixtures}
        onSuggestSelect={onSuggestSelect}
        location={new google.maps.LatLng(59.913868, 10.752245)}
        radius="20"
      />
      <div className="coords">
        <span className="field-label label--basic">Current coordinates:</span> {props.latitude.input.value}{' '}
        {props.longitude.input.value}
      </div>
      <Map {...props} />
    </div>
  )
}

// const WithScript = withScriptjs(Script)

// const defaultProps = {
//   googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_KEY}&v=3.exp&libraries=places`,
//   loadingElement: <div style={{ height: `100%` }} />,
//   containerElement: <div style={{ height: `300px` }} />,
//   mapElement: <div style={{ height: `100%` }} />
// }

const MapField = (props) => (
  <LoadScript googleMapsApiKey={GOOGLE_MAPS_KEY} libraries={GOOGLE_MAP_LIBRARIES}>
    <Script {...props} />
  </LoadScript>
)

const MemoizedMapField = memo(MapField)

export default MemoizedMapField
