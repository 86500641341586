import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import * as router from '../../services/router'
import { Divider, List, ListSubheader, ListItem, ListItemText, ListItemIcon } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ActionResources from '@mui/icons-material/SupervisorAccount'
import IconArtwork from '@mui/icons-material/Brush'
import IconExhibition from '@mui/icons-material/Visibility'
import IconReference from '@mui/icons-material/Bookmark'
import IconInstitution from '@mui/icons-material/Business'
import IconKeywords from '@mui/icons-material/FontDownload'
import IconDocuments from '@mui/icons-material/ImportContacts'
import IconCatalogue from '@mui/icons-material/ListAlt'
import './Menu.css'
import { COLOR_BLACK_LIGHT, COLOR_DARK_TEXT, COLOR_PRIMARY, COLOR_WHITE } from '../../config/colors'

//const isMenuActive = (pathname, regexp) => (pathname.match(regexp) ? 'menu-item active' : 'menu-item')
const isMenuActive = (pathname, regexp) => (pathname.match(regexp) ? true : false)

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: '80px',
    paddingTop: '12px',
    paddingBottom: '12px',
  },
  menuItemInactive: {
    color: COLOR_DARK_TEXT,
    borderRight: `4px solid transparent`,
  },
  menuItemActive: {
    color: COLOR_WHITE,
    backgroundColor: COLOR_BLACK_LIGHT,
    borderRight: `4px solid ${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: COLOR_BLACK_LIGHT,
    },
  },
  menuIcon: {
    color: 'inherit',
  },
  menuText: {
    '& span.MuiTypography-body1': {
      lineHeight: '16px',
    },
  },
  divider: {
    margin: '-1px 0px 0px',
    height: '1px',
    border: 'none',
    backgroundColor: COLOR_DARK_TEXT,
  },
  subHeader: {
    color: theme.palette.primary.main,
    backgroundColor: COLOR_PRIMARY,
    textTransform: 'uppercase',
  },
}))

const AdminMenu = () => {
  const location = useLocation()
  const classes = useStyles()

  return (
    <div className="left-menu d-none d-lg-block">
      <List className="menu-list">
        <ListSubheader className={classes.subHeader}>Navigation</ListSubheader>
        <ListItem
          button
          component={Link}
          to={router.ROUTE_ARTWORKS}
          className={`${classes.root} ${
            isMenuActive(location.pathname, router.ARTWORKS_ROUTE_REGEXP)
              ? classes.menuItemActive
              : classes.menuItemInactive
          }`}
        >
          <ListItemIcon className={classes.menuIcon}>
            <IconArtwork />
          </ListItemIcon>
          <ListItemText primary="Artworks" className={classes.menuText} />
        </ListItem>
        <Divider className={classes.divider} />

        <ListItem
          button
          component={Link}
          to={router.ROUTE_EXHIBITIONS}
          className={`${classes.root} ${
            isMenuActive(location.pathname, router.EXHIBITIONS_ROUTE_REGEXP)
              ? classes.menuItemActive
              : classes.menuItemInactive
          }`}
        >
          <ListItemIcon className={classes.menuIcon}>
            <IconExhibition />
          </ListItemIcon>
          <ListItemText primary="Exhibitions" className={classes.menuText} />
        </ListItem>
        <Divider className={classes.divider} />

        <ListItem
          button
          component={Link}
          to={router.ROUTE_PERSONS}
          className={`${classes.root} ${
            isMenuActive(location.pathname, router.PERSONS_ROUTE_REGEXP)
              ? classes.menuItemActive
              : classes.menuItemInactive
          }`}
        >
          <ListItemIcon className={classes.menuIcon}>
            <ActionResources />
          </ListItemIcon>
          <ListItemText primary="People" className={classes.menuText} />
        </ListItem>
        <Divider className={classes.divider} />

        <ListItem
          button
          component={Link}
          to={router.ROUTE_INSTITUTIONS}
          className={`${classes.root} ${
            isMenuActive(location.pathname, router.INSTITUTIONS_ROUTE_REGEXP)
              ? classes.menuItemActive
              : classes.menuItemInactive
          }`}
        >
          <ListItemIcon className={classes.menuIcon}>
            <IconInstitution />
          </ListItemIcon>
          <ListItemText primary="Institutions" className={classes.menuText} />
        </ListItem>
        <Divider className={classes.divider} />

        <ListItem
          button
          component={Link}
          to={router.ROUTE_REFERENCES}
          className={`${classes.root} ${
            isMenuActive(location.pathname, router.REFERENCES_ROUTE_REGEXP)
              ? classes.menuItemActive
              : classes.menuItemInactive
          }`}
        >
          <ListItemIcon className={classes.menuIcon}>
            <IconReference />
          </ListItemIcon>
          <ListItemText primary="References" className={classes.menuText} />
        </ListItem>

        <Divider className={classes.divider} />

        <ListItem
          button
          component={Link}
          to={router.ROUTE_KEYWORDS}
          className={`${classes.root} ${
            isMenuActive(location.pathname, router.KEYWORDS_ROUTE_REGEXP)
              ? classes.menuItemActive
              : classes.menuItemInactive
          }`}
        >
          <ListItemIcon className={classes.menuIcon}>
            <IconKeywords />
          </ListItemIcon>
          <ListItemText primary="Keywords" className={classes.menuText} />
        </ListItem>
        <Divider className={classes.divider} />

        <ListItem
          button
          component={Link}
          to={router.ROUTE_DOCUMENTS}
          className={`${classes.root} ${
            isMenuActive(location.pathname, router.DOCUMENTS_ROUTE_REGEXP)
              ? classes.menuItemActive
              : classes.menuItemInactive
          }`}
        >
          <ListItemIcon className={classes.menuIcon}>
            <IconDocuments />
          </ListItemIcon>
          <ListItemText primary="Archive" className={classes.menuText} />
        </ListItem>
        <Divider className={classes.divider} />

        <ListItem
          button
          component={Link}
          to={router.ROUTE_CATALOGUES}
          className={`${classes.root} ${
            isMenuActive(location.pathname, router.CATALOGUE_ROUTE_REGEXP)
              ? classes.menuItemActive
              : classes.menuItemInactive
          }`}
        >
          <ListItemIcon className={classes.menuIcon}>
            <IconCatalogue />
          </ListItemIcon>
          <ListItemText primary="Previous Catalogues" className={classes.menuText} />
        </ListItem>
        <Divider className={classes.divider} />

        <ListItem
          button
          component={Link}
          to={router.ROUTE_USERS}
          className={`${classes.root} ${
            isMenuActive(location.pathname, router.USERS_ROUTE_REGEXP)
              ? classes.menuItemActive
              : classes.menuItemInactive
          }`}
        >
          <ListItemIcon className={classes.menuIcon}>
            <ActionResources />
          </ListItemIcon>
          <ListItemText primary="Users" className={classes.menuText} />
        </ListItem>
        <Divider className={classes.divider} />
      </List>
    </div>
  )
}

const Menu = () => {
  return <AdminMenu />
}

export default Menu
