import React, { useState } from 'react'
import Modal from 'react-modal'
import { FieldArray } from 'redux-form'
import TextButton from '../general/TextButton'
import OutlinedButton from '../general/OutlinedButton'
import { Chip, Checkbox, FormControlLabel } from '@mui/material'

const findIndex = (items, item, titleField) => (items ? items.findIndex((i) => i[titleField] === item) : -1)
const isChecked = (items, item, titleField) => findIndex(items, item, titleField) !== -1
const toggle = (fields, item, titleField) => {
  const index = findIndex(fields.getAll(), item, titleField)
  index === -1 ? fields.push({ [titleField]: item }) : fields.remove(index)
}

const List = ({ fields, titleField, list }) => {
  return (
    <div className="flex-box" style={{ marginBottom: '10px' }}>
      {list && fields.length > 0 ? (
        fields.map((member, index, fields) => {
          const field = list.filter((i) => i.value === fields.get(index)[titleField])[0] ?? {}
          return (
            <Chip
              key={index}
              className={`repeater-field`}
              onDelete={() => fields.remove(index)}
              label={list ? field['title'] : ''}
            ></Chip>
          )
        })
      ) : (
        <div className="dark-text">None selected</div>
      )}
    </div>
  )
}

const SelectList = ({ fields, items, titleField }) => {
  const handleCheckboxChange = (item) => {
    toggle(fields, item.value, titleField)
  }

  return (
    <div>
      {items
        ? items.map((item, i) => (
            <div key={i} className={item.indent ? `indent-${item.indent}` : ''}>
              {item.parent ? (
                <div className="parent-box">{item.title}</div>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked(fields.getAll(), item.value, titleField)}
                      onChange={() => handleCheckboxChange(item)}
                    />
                  }
                  label={item.title}
                />
              )}
            </div>
          ))
        : 'Select type first'}
    </div>
  )
}

const CheckBoxList = ({ name, titleField, items }) => {
  const [isOpen, setIsOpen] = useState(false)

  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  return (
    <div className="row">
      <FieldArray name={name} component={List} titleField={titleField} list={items} />
      <div className="margin-top">
        <OutlinedButton color="secondary" onClick={open} label="Edit" />
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={close}
        className={{
          base: 'modal',
          afterOpen: 'modal-open',
          beforeClose: 'modal-close',
        }}
        overlayClassName={{
          base: 'modal-overlay',
          afterOpen: 'modal-overlay-open',
          beforeClose: 'modal-overlay-close',
        }}
      >
        <FieldArray name={name} component={SelectList} titleField={titleField} items={items} />
        <div className="right-controls">
          <TextButton label="Done" color="primary" onClick={close} type="button" />
        </div>
      </Modal>
    </div>
  )
}

export default CheckBoxList
