import * as actionTypes from '../actions/actionTypes'
import {
  crudInitialState,
  itemLoad,
  itemLoaded,
  itemLoadFailed,
  itemSave,
  itemSaved,
  itemSaveFailed,
  itemsLoad,
  itemsLoaded,
  itemsLoadFailed
} from './crud'

const initialState = crudInitialState

const actionHandlers = {
  [actionTypes.ARCHIVES_LOAD]: itemsLoad,
  [actionTypes.ARCHIVES_LOADED]: itemsLoaded,
  [actionTypes.ARCHIVES_LOAD_FAILED]: itemsLoadFailed,
  [actionTypes.ARCHIVE_LOAD]: itemLoad,
  [actionTypes.ARCHIVE_LOADED]: itemLoaded,
  [actionTypes.ARCHIVE_LOAD_FAILED]: itemLoadFailed,
  [actionTypes.ARCHIVE_UPDATE]: itemSave,
  [actionTypes.ARCHIVE_CREATE]: itemSave,
  [actionTypes.ARCHIVE_UPDATED]: itemSaved,
  [actionTypes.ARCHIVE_CREATED]: itemSaved,
  [actionTypes.ARCHIVE_UPDATE_FAILED]: itemSaveFailed,
  [actionTypes.ARCHIVE_CREATE_FAILED]: itemSaveFailed
}

export default (state = initialState, { type, payload }) => {
  const handler = actionHandlers[type] 
  return handler ? handler(state, payload) : state
}
