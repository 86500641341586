//
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import * as router from '../../services/router'
import './Auth.css'
import { login } from '../../actions/authActions'
import AuthForm from './AuthForm'
import { Link } from '@mui/material'
import { DATABASES } from '../../config/config'
import { Logo } from './Logo'

const Auth = () => {
  const colorAccent = useSelector((state) => state.auth.colorAccent)
  const error = useSelector((state) => state.auth.error)
  const loading = useSelector((state) => state.auth.loading)
  const dispatch = useDispatch()
  const onSubmit = (credentials) => dispatch(login(credentials))
  const [selectedColor, setSelectedColor] = useState(colorAccent)
  const handleColorChange = (value) => {
    if (value) {
      const selectedDatabase = DATABASES.find((db) => db.id === value.id)
      if (selectedDatabase) {
        setSelectedColor(selectedDatabase.accent_color)
      } else {
        setSelectedColor(colorAccent)
      }
    } else {
      setSelectedColor(colorAccent)
    }
  }

  return (
    <div className="auth">
      <Logo color={selectedColor} />
      <AuthForm onSubmit={onSubmit} loading={loading} handleColorChange={handleColorChange} />
      {error !== undefined && <div className="login-error">{error}</div>}
      <div className="centered auth__link">
        <Link component={RouterLink} to={router.ROUTE_FORGOT_PASSWORD} className="">
          Forgot password?
        </Link>
      </div>
    </div>
  )
}

export default Auth
