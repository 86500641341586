import React from 'react'
import { MenuItem } from '@mui/material'
import { Field, reduxForm } from 'redux-form'
import { KEYWORDS_CATEGORIES, KEYWORDS_SUBCATEGORIES } from '../../../config/config'
import { KEYWORD_FORM } from '../../../services/forms'
import { CustomSelect } from '../../general/CustomSelect'
import { CustomTextField } from '../../general/CustomTextField'

const validate = (values) => {
  const errors = {}
  const requiredFields = ['firstname', 'lastname']
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })

  return errors
}

const KeywordFormPresentation = ({ handleSubmit, lang, upload, changeFieldValue, category = 'architecture' }) => {
  return (
    <form className="form-container keyword-form" onSubmit={handleSubmit}>
      <div className="field-label">Category</div>
      <Field
        name={`category`}
        component={CustomSelect}
        // onChange={() => {
        //   // changeFieldValue('subcategory', [])
        // }}
      >
        {KEYWORDS_CATEGORIES.map((item, i) => (
          <MenuItem key={i} value={item.value}>
            {item.title}
          </MenuItem>
        ))}
      </Field>
      <div className="field-label">Subcategory</div>
      <Field
        name={`subcategory`}
        component={CustomSelect}
        // onChange={() => {
        //   // changeFieldValue('techniques', [])
        // }}
      >
        {KEYWORDS_SUBCATEGORIES[category].map((item, i) => (
          <MenuItem key={i} value={item.value}>
            {item.title}
          </MenuItem>
        ))}
      </Field>
      <div className="repeater-field">
        <Field 
        id="keyword-input" 
        name="keyword" 
        component={CustomTextField} 
        label="Keyword"/>
      </div>
      <div className="repeater-field">
        <Field id="keyword_nb-input" name="keyword_nb" component={CustomTextField} label="Keyword (NB)" />
      </div>
    </form>
  )
}

const formProps = {
  form: KEYWORD_FORM,
  destroyOnUnmount: false,
  enableReinitialize: true,
  validate,
}

const KeywordForm = reduxForm(formProps)(KeywordFormPresentation)

export default KeywordForm
