import React from 'react'
import { CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  root: {
    alignSelf: 'center',
    zIndex: '2',
  },
})

export const CustomRefreshIndicator = ({ size = 30, color = 'primary', small = false }) => {
  const classes = useStyles()

  return (
    <div className={`img-holder ${small ? 'upload-loader' : ''}`}>
      <CircularProgress
        classes={{
          root: `${classes.root} `,
        }}
        size={size}
        color={color}
      />
    </div>
  )
}
