import React from 'react'
import { Checkbox, FormControlLabel } from '@mui/material'
import { PUBLISHED, REVIEWED } from '../../utils/constants'
import { useSelector } from 'react-redux'
import { formValueSelector } from 'redux-form'

export const PublishedStatus = ({ changeFieldValue, formName }) => {
  const reviewedState = useSelector((state) => formValueSelector(formName)(state, REVIEWED))
  const publishedState = useSelector((state) => formValueSelector(formName)(state, PUBLISHED))
  const handleCheckboxChange = (event, name) => {
    changeFieldValue(name, event.target.checked ? 1 : 0)
  }

  return (
    <div className="row flex-box">
      <FormControlLabel
        control={
          <Checkbox
            name={REVIEWED}
            checked={reviewedState === 1}
            onChange={(event) => handleCheckboxChange(event, REVIEWED)}
          />
        }
        label="Reviewed"
      />
      <FormControlLabel
        control={
          <Checkbox
            name={PUBLISHED}
            checked={publishedState === 1}
            onChange={(event) => handleCheckboxChange(event, PUBLISHED)}
          />
        }
        label="Published"
      />
    </div>
  )
}
