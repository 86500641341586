import * as React from 'react'
import { Field, FieldArray } from 'redux-form'
import Repeater from './Repeater'
import { INSCRIPTION_LOCATIONS, INSCRIPTION_TECHNIQUES, SIGNATURE_TYPES } from '../../config/config'
import { Tooltip, IconButton, MenuItem } from '@mui/material'
import { ReferenceField } from './ReferenceField'
import { Close } from '@mui/icons-material'
import { ARTWORK_FORM } from '../../services/forms'
import { COLOR_LIGHT_TEXT } from '../../config/colors'
import { CustomSelect } from '../general/CustomSelect'
import { CustomTextField } from '../general/CustomTextField'

const ArtworkInscriptionsField = ({ member, index, fields, lang }) => (
  <div key={index} className="flex title-row">
    <div className="repeater-field">
      {index === 0 ? <div className="field-label">Type</div> : null}
      <Field name={`${member}.type`} component={CustomSelect}>
        {SIGNATURE_TYPES.map((item, i) => (
          <MenuItem key={i} value={item.value}>
            {item.title}
          </MenuItem>
        ))}
      </Field>
    </div>
    <div className="repeater-field">
      {index === 0 ? <div className="field-label">Location</div> : null}
      <Field name={`${member}.location`} component={CustomSelect}>
        {INSCRIPTION_LOCATIONS.map((item, i) => (
          <MenuItem key={i} value={item.value}>
            {item.title}
          </MenuItem>
        ))}
      </Field>
    </div>
    <div className="repeater-field">
      {index === 0 ? <div className="field-label">Technique</div> : null}
      <Field name={`${member}.technique`} component={CustomSelect}>
        {INSCRIPTION_TECHNIQUES.map((item, i) => (
          <MenuItem key={i} value={item.value}>
            {item.title}
          </MenuItem>
        ))}
      </Field>
    </div>
    <div className="repeater-field">
      {index === 0 ? <div className="field-label">Inscription</div> : null}
      <Field
        id={`${member}.value-${index}-input`}
        label="Inscription"
        hideLabel={true}
        name={`${member}.value`}
        component={CustomTextField}
        type="text"
      />
      <ReferenceField name={`references.inscriptions_${index}`} formType={ARTWORK_FORM} />
    </div>
    <Tooltip title="Remove">
      <IconButton
        className="repeater-remove"
        onClick={() => fields.remove(index)}
        size="large">
        <Close style={{ color: COLOR_LIGHT_TEXT }} />
      </IconButton>
    </Tooltip>
  </div>
)

const ArtworkTitlesFields = Repeater(ArtworkInscriptionsField, undefined, 'Add inscription')

const ArtworkInscriptions = ({ lang }) => (
  <div>
    <div className="section-title">Inscriptions</div>
    <FieldArray name="inscriptions" component={ArtworkTitlesFields} lang={lang} />
  </div>
)

export default ArtworkInscriptions
