import * as React from 'react'
import { FieldArray } from 'redux-form'
import { DATE_TYPE_BETWEEN, DATE_TYPES_PROVENANCE } from '../../../config/config'

const getDateType = item => {
  const type = DATE_TYPES_PROVENANCE.find(date => date.value === item.pivot.date_type)
  return type ? type.title : ''
}

const Item = ({ item }) => (
  <tr>
    <td>
      {item.titles[0] && item.titles[0].translations[0] && item.titles[0].translations[0].title
        ? item.titles[0].translations[0].title
        : item.titles[0].translations[1].title}
    </td>
    <td className="year-cell">{item.pivot.date_type === DATE_TYPE_BETWEEN ? '' : item.pivot.date_year}</td>
    <td>{getDateType(item)}</td>
    <td className="year-cell">{item.pivot.from_year}</td>
    <td className="">{item.pivot.to_year}</td>
  </tr>
)

const List = ({ fields, index }) => (
  <div key={index}>
    {fields.length > 0 ? (
      <div>
        <h1>Provenance</h1>
        <table className="provenance-table">
          <thead>
            <tr>
              <th>Title</th>
              <th className="wide-cell">Year</th>
              <th>Accuracy</th>
              <th className="wide-cell">From</th>
              <th className="">To</th>
            </tr>
          </thead>
          <tbody>
            {fields.map((member, index, fields) => (
              <Item key={index} item={fields.get(index)} />
            ))}
          </tbody>
        </table>
      </div>
    ) : null}
  </div>
)

export const ArtworksProvenance = () => <FieldArray name="artworks" component={List} />
