import React from 'react'
import TableRow from '@mui/material/TableRow'
import * as router from '../../services/router'
import { makeList } from '../general/List'
import { loadReferences, referencesOrder } from '../../actions/referencesActions'
import { formatDate, getTitleByValue } from '../../utils/utils'
import { REFERENCE_TYPES } from '../../config/config'
import { CustomTableCell, makeSortableTableHeader } from '../general/Table'

const headers = [
  { field: 'author', title: 'Author' },
  { field: 'year', title: 'Year' },
  { field: 'title', title: 'Title' },
  { field: 'type', title: 'Type' },
  { field: 'updated_at', title: 'Last Edited' }
]
const ListTableHead = makeSortableTableHeader(headers)

const renderRow = ({ item, onClick }) => (
  <TableRow onClick={onClick}>
    <CustomTableCell>{item.author}</CustomTableCell>
    <CustomTableCell>{item.year}</CustomTableCell>
    <CustomTableCell>{item.title}</CustomTableCell>
    <CustomTableCell>{item.type ? getTitleByValue(item.type, REFERENCE_TYPES) : ''}</CustomTableCell>
    <CustomTableCell>{item.updated_at ? formatDate(item.updated_at) : ''}</CustomTableCell>
  </TableRow>
)

const options = {
  model: 'references',
  loadFn: loadReferences,
  renderHeader: ListTableHead,
  renderRow: renderRow,
  paginationRoute: router.ROUTE_REFERENCES,
  editRoute: router.ROUTE_REFERENCE_EDIT,
  createRoute: router.ROUTE_REFERENCE_CREATE,
  title: 'References',
  orderFn: orderBy => referencesOrder(orderBy)
}

const References = makeList(options)

export default References
