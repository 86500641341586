import React from 'react'
import * as router from '../../../services/router'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import { loadDocumentsAll, documentsOrder } from '../../../actions/documentsActions'
import { TopSearch } from '../../header/TopSearch'
import { formatDate, getTitleByValue, sortBy } from '../../../utils/utils'
import Filters from './Filters'
import { CustomTableCell, makeSortableTableHeader } from '../../general/Table'
import { getOrder } from '../../../reducers/crud'
import { Add } from '@mui/icons-material'
import { DOCUMENT_SUBTYPES, DOCUMENT_TYPES } from '../../../config/config'
import { loadProvenancePersons } from '../../../actions/provenancePersonsActions'
import DataProviderFunctional from '../../loader/DataProviderFunctional'
import TextButton from '../../general/TextButton'
import { useTheme } from '@mui/material'

const headers = [
  { field: 'title', title: 'Title' },
  { field: 'author', title: 'Author' },
  { field: 'recipient', title: 'Recipient' },
  { field: 'date', title: 'Date' },
  { field: 'type', title: 'Type' },
  { field: 'subtype', title: 'Subtype' },
  { field: 'accession_number', title: 'Accession Number' },
  { field: 'updated_at', title: 'Last Edited' },
]

const ListTableHead = makeSortableTableHeader(headers)

const TableRows = ({ items, sortOrder, goToUrl, orderBy, order, persons }) => {
  const itemsWithData = items.map((item) => {
    return {
      ...item,
    }
  })

  sortBy(itemsWithData, orderBy, order)

  return itemsWithData.map((item) => (
    <TableRow key={item.id} onClick={() => goToUrl(router.ROUTE_DOCUMENT_EDIT + item.id)}>
      <CustomTableCell>{`${item.title ? item.title : ''}`}</CustomTableCell>
      <CustomTableCell>{getAuthors(item.documents_authors, item.documents_authors_institutions)}</CustomTableCell>
      <CustomTableCell>{getAuthors(item.documents_recipients, item.documents_recipients_institutions)}</CustomTableCell>
      <CustomTableCell>{`${item.date ? item.date : ''}`}</CustomTableCell>
      <CustomTableCell>{item.type ? getTitleByValue(item.type, DOCUMENT_TYPES) : ''}</CustomTableCell>
      <CustomTableCell>{`${
        item.subtype ? DOCUMENT_SUBTYPES[item.type].find((i) => i.value === item.subtype).title : ''
      }`}</CustomTableCell>
      <CustomTableCell>{`${item.accession_number ? item.accession_number : ''}`}</CustomTableCell>
      <CustomTableCell>{item.updated_at ? formatDate(item.updated_at) : ''}</CustomTableCell>
    </TableRow>
  ))
}

const DocumentsPresenter = () => {
  const theme = useTheme()
  const persons = useSelector((state) => state.provenancePersons.items)
  const documents = useSelector((state) => getDocuments(state))
  const { order, orderBy } = useSelector((state) => getOrder(state, 'documents'))
  const dispatch = useDispatch()
  const goToUrl = (url) => dispatch(push(url))
  const orderFn = (orderBy) => () => dispatch(documentsOrder(orderBy))

  return (
    <div className="exhibitions">
      <TopSearch title="Documents" FiltersComponent={Filters}>
        <div className="item-right">
          <TextButton
            label="Add document"
            color="primary"
            startIcon={<Add  style={{ color: theme.palette.primary.main }} />}
            onClick={() => goToUrl(router.ROUTE_DOCUMENT_CREATE)}
          />
        </div>
      </TopSearch>

      <Table className="non-fixed">
        <ListTableHead orderBy={orderBy} order={order} orderFn={orderFn} />
        <TableBody>
          {documents && (
            <TableRows orderBy={orderBy} order={order} items={documents} goToUrl={goToUrl} persons={persons} />
          )}
        </TableBody>
      </Table>
    </div>
  )
}

const getDocuments = (state) => {
  const documents = state.documents && state.documents.items ? Object.values(state.documents.items) : null
  if (state.filters.visible) {
    //opposite fix
    return documents
  } else {
    return documents.filter((k) => {
      let searchable = `${k.title}`
      let recipients = []
      let authors = []
      if (k.documents_recipients && k.documents_recipients.length > 0) {
        recipients = k.documents_recipients.map((recipient) => `${recipient.firstname} ${recipient.lastname}`)
      }
      if (k.documents_authors && k.documents_authors.length > 0) {
        authors = k.documents_authors.map((auth) => `${auth.firstname} ${auth.lastname}`)
      }
      searchable = searchable + k.subtype
      searchable = searchable + recipients.toString()
      searchable += k.date
      searchable = searchable + authors.toString()
      searchable = searchable.toLowerCase()
      return searchable.includes(state.filters.q.toLowerCase())
    })
  }
}

const getName = (id, persons) => {
  const item = Object.values(persons).find((i) => id === i.id)
  if (!item) {
    return ''
  }
  return (item.firstname ? item.firstname : '') + ' ' + (item.lastname ? item.lastname : '')
}

const getInstitutionName = (id, institutions) => {
  const item = Object.values(institutions).find((i) => id === i.id)
  if (!item) {
    return ''
  }
  return item.name ? item.name : ''
}

export const getPersonsList = (items) => {
  return items.map((item) => getName(item.id, items)).join(', ')
}

export const getInstitutionsList = (items) => {
  return items.map((item) => getInstitutionName(item.id, items)).join(', ')
}

export const getAuthors = (personsArray, institutionsArray) => {
  const persons = personsArray ? getPersonsList(personsArray) : ''
  const join = personsArray && personsArray.length > 0 && institutionsArray && institutionsArray.length > 0 ? ', ' : ' '
  const institutions = institutionsArray ? getInstitutionsList(institutionsArray) : ''
  return persons + join + institutions
}

const Documents = () => {
  const loading = useSelector((state) => state.documents.state)
  const error = useSelector((state) => state.documents.error)
  const watch = 'page'
  const dispatch = useDispatch()
  const load = () => {
    dispatch(loadDocumentsAll())
    dispatch(loadProvenancePersons())
  }
  return (
    <DataProviderFunctional error={error} load={load} loading={loading} watch={watch} component={DocumentsPresenter} />
  )
}

export default Documents
