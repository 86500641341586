import { MenuItem } from '@mui/material'
import * as React from 'react'
import { Field } from 'redux-form'
import { ARTWORK_DATE_TYPES } from '../../config/config'
import { ARTWORK_FORM } from '../../services/forms'
import DateFromTo from './DateFromTo'
import { ReferenceField } from './ReferenceField'
import { CustomSelect } from '../general/CustomSelect'

const ArtworkDate = () => (
  <div className="flex-box">
    <div>
      <div className="field-label">Date</div>
      <Field name="other_date" component={DateFromTo} />
    </div>
    <div>
      <div className="field-label">Accuracy</div>
      <div className="flex-box-centered">
        <Field name="other_date" component={CustomSelect}>
          {ARTWORK_DATE_TYPES.map((item, i) => (
            <MenuItem key={i} value={item.value}>
              {item.title}
            </MenuItem>
          ))}
        </Field>
        <ReferenceField name="references.other_date" formType={ARTWORK_FORM} />
      </div>
    </div>
  </div>
)

export default ArtworkDate
