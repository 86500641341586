import React from 'react'
import { push } from 'connected-react-router'
import * as router from '../../../services/router'
import Pagination from '../../pagination/Pagination'
import { artworksOrder, loadArtworks } from '../../../actions/artworksActions'
import { TopSearch } from '../../header/TopSearch'
import GridArtwork from './GridArtwork'
import ControlPanel, { ViewMode } from './ControlPanel'
import './Artworks.css'
import { formatDate, getArtworkTitle, or, artworkSortBy, getArtworkType } from '../../../utils/utils'
import { COLOR_BLACK } from '../../../config/colors'
import Filters from './Filters'
import { useTheme, TableCell } from '@mui/material'
import { Add, CheckCircle } from '@mui/icons-material'
import TableSortLabel from '@mui/material/TableSortLabel/TableSortLabel'
import { CustomTableCell } from '../../general/Table'
import TableHead from '@mui/material/TableHead/TableHead'
import Table from '@mui/material/Table/Table'
import TableRow from '@mui/material/TableRow/TableRow'
import TableBody from '@mui/material/TableBody/TableBody'
import { ARTWORK_TYPES, TYPES } from '../../../config/config'
import DataProviderFunctional from '../../loader/DataProviderFunctional'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import TextButton from '../../general/TextButton'


const ArtworksPresenter = () => {
  const theme = useTheme()
  const { page, total, orderBy, order, pages, isGrid } = useSelector((state) => state.artworks)
  const users = useSelector((state) => state.users.items)
  const currentPage = [...or(pages[page], [])]
  artworkSortBy(currentPage, orderBy, order)
  const artworks = currentPage
  const dispatch = useDispatch()
  const goToUrl = (id) => dispatch(push(router.ROUTE_ARTWORK_EDIT + id))
  const goTo = (route) => dispatch(push(route))
  const toggleViewMode = (isGrid) => dispatch(toggleViewMode(isGrid))
  const orderFn = (orderBy) => () => dispatch(artworksOrder(orderBy))

  return (
    <div className="artworks">
      <TopSearch
        title="Artworks"
        FiltersComponent={Filters}
        resultsRoute={router.ROUTE_ARTWORKS}
        resultsLoader={loadArtworks}
        hideSearch={true}
      >
        <div className="item-right">
          <TextButton
            label="Add Artwork"
            color="primary"
            startIcon={<Add style={{ color: theme.palette.primary.main }} />}
            onClick={() => goTo(router.ROUTE_ARTWORK_CREATE)}
          />
        </div>
      </TopSearch>

      {isGrid ? (
        <React.Fragment>
          <ControlPanel />
          <div className="grid">
            {artworks.map((artwork, i) => (
              <GridArtwork key={i} users={users} artwork={artwork} onClick={() => goToUrl(artwork.stable_id)} />
            ))}
          </div>
        </React.Fragment>
      ) : (
        <Table className="non-fixed collapsed">
          <TableHead>
            <TableRow>
              <CustomTableCell>
                <ViewMode />
              </CustomTableCell>
              {headers.map((header, i) => (
                <CustomTableCell key={i}>
                  <TableSortLabel
                    key={i}
                    active={orderBy === header.field}
                    direction={order}
                    onClick={orderFn(header.field)}
                  >
                    {header.title}
                  </TableSortLabel>
                </CustomTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {artworks.map((artwork, i) => (
              <TableRow key={i} onClick={() => goToUrl(artwork.stable_id)}>
                <TableCell>
                  <div className="table-img-holder">
                    {artwork.images[0] ? <img src={artwork.images[0].thumb_url} alt="" /> : null}
                  </div>
                </TableCell>
                <TableCell>{getArtworkTitle(artwork)}</TableCell>
                <TableCell>{getArtworkType(TYPES, artwork.type)}</TableCell>
                <TableCell>
                  {artwork.type === ARTWORK_TYPES.PAINTING ? artwork.nr_loge_k : artwork.verk_id}
                </TableCell>
                <TableCell>
                  <CheckCircle style={{ color: artwork.published ? theme.palette.primary.main : COLOR_BLACK}} />
                </TableCell>
                <TableCell>
                  <CheckCircle style={{ color: artwork.reviewed ? theme.palette.primary.main : COLOR_BLACK}} />
                </TableCell>
                <TableCell>{formatDate(artwork.updated_at)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <Pagination currentPage={page} total={total} route={router.ROUTE_ARTWORKS} />
    </div>
  )
}

const headers = [
  { field: 'titles', title: 'Title' },
  { field: 'type', title: 'Type' },
  { field: 'verk_id', title: 'ID' },
  { field: 'published', title: 'Published' },
  { field: 'reviewed', title: 'Approved' },
  { field: 'updated_at', title: 'Last edited' },
]

const Artworks = () => {
  const loading = useSelector((state) => state.artworks.state)
  const error = useSelector((state) => state.artworks.error)
  const watch = 'page'
  const { page } = useParams()
  const dispatch = useDispatch()
  const load = () => {
    dispatch(loadArtworks(page))
  }
  return (
    <DataProviderFunctional
      error={error}
      load={load}
      loading={loading}
      watch={watch}
      component={ArtworksPresenter}
      page={page}
    />
  )
}

export default Artworks
