import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox, FormControlLabel } from '@mui/material'
import { AUTHENTICITY, TYPES, WHEREABOUTS } from '../../../config/config'
import { toggleFilter, setFilter } from '../../../actions/filtersActions'
import { replace } from 'connected-react-router'
import { CustomTextField } from '../../general/CustomTextField'
import ContainedButton from '../../general/ContainedButton'

const List = ({ type, items, selected, toggle }) => {

  return (
    <div className="filter-list">
      {items.map((item, i) => (
        <div className="light-radio filter-item" key={i}>
          <FormControlLabel
            
            label={item.title}
            control={
              <Checkbox
                key={i}
                checked={selected[item.value] === true}
                onChange={() => toggle(type, item.value)}
              />
            }
          />
        </div>
      ))}
    </div>
  )
}

const Filters = ({ resultsRoute, resultsLoader }) => {
  const dispatch = useDispatch()
  const filters = useSelector((state) => state.filters)

  const handleSearch = () => {
    dispatch(replace(resultsRoute))
    dispatch(resultsLoader())
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
      e.target.blur()
    }
  }

  const handleToggleFilter = (type, value) => {
    dispatch(toggleFilter(type, value))
  }

  const handleFilterChange = (field, value) => {
    dispatch(setFilter(field, value))
  }

  return (
    <div className="filters">
      <div className="row filter-section">
        <div className="field-label">Search</div>
        <div className="flex-box-centered">
          <div className="full-field" style={{ flex: '1' }}>
            <CustomTextField
              width="full"
              name="q"
              value={filters.q}
              onChange={(_, value) => handleFilterChange('q', value)}
              onKeyPress={handleKeyPress}
            />
          </div>
          <ContainedButton primary={true} label="Search" onClick={handleSearch} />
        </div>
      </div>
      <div className="grid-3">
        <div className="filter-section">
          <div className="field-label">TYPES</div>
          <List type="types" items={TYPES} selected={filters.types} toggle={handleToggleFilter} />
        </div>
        <div className="filter-section">
          <div className="field-label">AUTHENTICITY</div>
          <List type="authenticity" items={AUTHENTICITY} selected={filters.authenticity} toggle={handleToggleFilter} />
        </div>
        <div className="filter-section">
          <div className="field-label">WHEREABOUTS</div>
          <List type="whereabouts" items={WHEREABOUTS} selected={filters.whereabouts} toggle={handleToggleFilter} />
        </div>
        <div className="filter-section">
          <div className="field-label">YEAR</div>
          <div className="flex">
            <div className="filter-gap">
              <div>From</div>
              <CustomTextField
                name="from"
                value={filters.from}
                onChange={(_, value) => handleFilterChange('from', value)}
                width="short"
              />
            </div>
            <div className="filter-gap">
              <div>To</div>
              <CustomTextField
                name="to"
                value={filters.to}
                onChange={(_, value) => handleFilterChange('to', value)}
                width="short"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Filters
