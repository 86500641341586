import React from 'react'
import Select from 'react-select'
import { CustomDropdownIndicator } from '../../general/CustomDropdownIndicator'

export const AutoComplete = ({ input, changeFieldValue, persons }) => {
  return (
    <Select
      className="react-select-container"
      classNamePrefix="react-select"
      name="persons"
      options={persons}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      value={persons.find((person) => person.id === input.value) || null}
      onChange={(selectedOption) => changeFieldValue(input.name, selectedOption?.id || null)}
      isClearable={false}
      isSearchable={true}
      components={{ DropdownIndicator: CustomDropdownIndicator }}
    />
  )
}
