import { EditorState, Modifier } from 'draft-js'
function isSafari() {
  return typeof navigator !== 'undefined' && /Version\/[\d\.]+.*Safari/.test(navigator.userAgent);
}
export function getSelected() {
  var t = ''
  if (window.getSelection) {
    t = window.getSelection()
  } else if (document.getSelection) {
    t = document.getSelection()
  } else if (document.selection) {
    t = document.selection.createRange().text
  }
  return t
}
export function getPosition(selected) {
  var range = selected.getRangeAt(0)
  // hack for Safari
  if (isSafari()) {
    range = range.cloneRange()
    range.setStart(range.startContainer, 0)
  }
  return range.getBoundingClientRect()
}
export const createEntity = (editorState, type, data) => {
  const { contentState, selectionState } = getEditorData(editorState)
  const contentStateWithEntity = contentState.createEntity(type, 'IMMUTABLE', data);
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const textWithEntity = Modifier.applyEntity(contentState, selectionState, entityKey)
  const newEditorState = EditorState.push(
    editorState,
    textWithEntity,
    'create-entity'
  );
  return newEditorState;
};
export function removeEntity(editorState, entityType) {
  const { contentState, selectionState } = getEditorData(editorState)
  const blocks = getSelectedBlocksByType(editorState, entityType)
  if (blocks.length !== 0) {
    let newContentState = contentState
    blocks.forEach(({ block, start, end }, key) => {
      const blockKey = block.getKey()
      newContentState = Modifier.applyEntity(
        newContentState,
        selectionState.merge({
          anchorKey: blockKey,
          focusKey: blockKey,
          anchorOffset: start,
          focusOffset: end,
          isBackward: false,
        }),
        null
      )
    })
    return EditorState.set(editorState, {
      currentContent: newContentState,
    })
  }
}
export function findEntityInSelection(editorState, entityType) {
  const { startKey, startOffset, endOffset } = getEditorData(editorState)
  const entities = getEntitiesByBlockKey(editorState, entityType, startKey)
  if (entities.length === 0) return null
  let selectedEntity = null
  entities.forEach((entity) => {
    const { blockKey, start, end } = entity
    if (
      blockKey === startKey &&
      ((startOffset > start && startOffset < end) ||
        (endOffset > start && endOffset < end) ||
        (startOffset === start && endOffset === end))
    ) {
      selectedEntity = entity
    }
  })
  return selectedEntity
}
export function getEditorData(editorState) {
  return {
    contentState: editorState.getCurrentContent(),
    inlineStyle: editorState.getCurrentInlineStyle(),
    selectionState: editorState.getSelection(),
    hasFocus: editorState.getSelection().getHasFocus(),
    isCollapsed: editorState.getSelection().isCollapsed(),
    startKey: editorState.getSelection().getStartKey(),
    startOffset: editorState.getSelection().getStartOffset(),
    endKey: editorState.getSelection().getEndKey(),
    endOffset: editorState.getSelection().getEndOffset(),
  }
}
export function getEntitiesByBlockKey(editorState, entityType = null, blockKey = null) {
  return getEntities(editorState, entityType).filter((entity) => entity.blockKey === blockKey)
}
export function findEntities(entityType, contentBlock, callback, contentState) {
  return contentBlock.findEntityRanges((character) => entityFilter(character, entityType, contentState), callback)
}
function entityFilter(character, entityType, contentState) {
  const entityKey = getEntity(character)
  return entityKey !== null && contentState.getEntity(entityKey).getType() === entityType
}
function getEntity(character) {
  return character.getEntity()
}
export function getEntities(editorState, entityType = null, selectedEntityKey = null) {
  const { contentState } = getEditorData(editorState)
  const entities = []
  contentState.getBlocksAsArray().forEach((block) => {
    let selectedEntity = null
    block.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity()
        if (entityKey !== null) {
          const entity = contentState.getEntity(entityKey)
          if (!entityType || (entityType && entity.getType() === entityType)) {
            if (selectedEntityKey === null || (selectedEntityKey !== null && entityKey === selectedEntityKey)) {
              selectedEntity = {
                entityKey,
                blockKey: block.getKey(),
                entity: contentState.getEntity(entityKey),
              }
              return true
            } else {
              return false
            }
          }
        }
        return false
      },
      (start, end) => {
        entities.push({ ...selectedEntity, start, end })
      }
    )
  })
  return entities
}
function getSelectedBlocks(editorState) {
  const { contentState, startKey, endKey } = getEditorData(editorState)
  const blocks = []
  let block = contentState.getBlockForKey(startKey)
  while (true) {
    blocks.push(block)
    const blockKey = block.getKey()
    if (blockKey === endKey) break
    block = contentState.getBlockAfter(blockKey)
  }
  return blocks
}
function getSelectedBlocksByType(editorState, entityType) {
  const { contentState, startKey, endKey, startOffset, endOffset } = getEditorData(editorState)
  const blocks = []
  getSelectedBlocks(editorState).forEach((block) => {
    const blockKey = block.getKey()
    const blockStartOffset = blockKey === startKey ? startOffset : 0
    const blockEndOffset = blockKey === endKey ? endOffset : block.getLength()
    findEntities(
      entityType,
      block,
      (start, end) => {
        if (Math.max(start, blockStartOffset) <= Math.min(end, blockEndOffset)) {
          const entityKey = block.getEntityAt(start)
          const text = block.getText().slice(start, end)
          const url = contentState.getEntity(entityKey).getData().url
          blocks.push({ text, url, block, start, end })
        }
      },
      contentState
    )
  })
  return blocks
}
export const extendSelectionByData = (editorState, data) => {
  const { selectionState, startKey, startOffset, endKey, endOffset } = getEditorData(editorState)
  let anchorKey = startKey
  let focusKey = endKey
  let anchorOffset = startOffset
  let focusOffset = endOffset
  data.forEach(({ blockKey, start, end }, key) => {
    if (key === 0) {
      anchorKey = blockKey
      anchorOffset = start
    }
    if (key === data.length - 1) {
      focusKey = blockKey
      focusOffset = end
    }
  })
  const state = Object.assign({}, anchorKey ? { anchorKey } : {}, {
    focusKey,
    anchorOffset,
    focusOffset,
    isBackward: false,
  })
  const newSelectionState = selectionState.merge(state)
  return EditorState.acceptSelection(editorState, newSelectionState)
}
