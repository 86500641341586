import * as actionTypes from '../actions/actionTypes'
import { crudInitialState, itemsLoad, itemsLoadedAll, itemsLoadFailed } from './crud'

const initialState = crudInitialState

const actionHandlers = {
  [actionTypes.COUNTRIES_LOAD]: itemsLoad,
  [actionTypes.COUNTRIES_LOADED]: itemsLoadedAll,
  [actionTypes.COUNTRIES_LOAD_FAILED]: itemsLoadFailed
}

export default (state = initialState, { type, payload }) => {
  const handler = actionHandlers[type] // Retrieve the handler
  return handler ? handler(state, payload) : state // Invoke handler if it exists, else return current state
}
