import React from 'react'
import { push } from 'connected-react-router'
import * as router from '../../../services/router'
import { Table, TableBody, TableRow, useTheme } from '@mui/material'
import { Add } from '@mui/icons-material'
import { cataloguesOrder, loadCataloguesAll } from '../../../actions/cataloguesActions'
import { TopSearch } from '../../header/TopSearch'
import { sortBy } from '../../../utils/utils'
import Filters from './Filters'
import { CustomTableCell, makeSortableTableHeader } from '../../general/Table'
import { getOrder } from '../../../reducers/crud'
import { useDispatch, useSelector } from 'react-redux'
import DataProviderFunctional from '../../loader/DataProviderFunctional'
import TextButton from '../../general/TextButton'

const headers = [
  { field: 'title_en', title: 'Title (English)' },
  { field: 'title_nb', title: 'Title (Norwegian)' },
  { field: 'artwork_id', title: 'Artworks ID' },
]

const ListTableHead = makeSortableTableHeader(headers)

const TableRows = ({ items, goToUrl, orderBy, order }) => {
  const itemsWithData = [...items]
  sortBy(itemsWithData, orderBy, order)

  return itemsWithData.map((item) => (
    <TableRow key={item.id} onClick={() => goToUrl(router.ROUTE_CATALOGUE_EDIT + item.id)}>
      <CustomTableCell>{item.title_en}</CustomTableCell>
      <CustomTableCell>{item.title_nb}</CustomTableCell>
      <CustomTableCell>{item.artworks_id}</CustomTableCell>
    </TableRow>
  ))
}

const CataloguesPresenter = () => {
  const theme = useTheme()
  const catalogues = useSelector((state) => getCatalogues(state))
  const { order, orderBy } = useSelector((state) => getOrder(state, 'catalogues'))
  const dispatch = useDispatch()
  const goToUrl = (url) => dispatch(push(url))
  const orderFn = (orderBy) => () => dispatch(cataloguesOrder(orderBy))

  return (
    <div className="catalogues">
      <TopSearch title="Catalogues" FiltersComponent={Filters}>
        <div className="item-right">
          <TextButton
            label="Add catalogue"
            color="primary"
            startIcon={<Add  style={{ color: theme.palette.primary.main }} />}
            onClick={() => goToUrl(router.ROUTE_CATALOGUE_CREATE)}
          />
        </div>
      </TopSearch>

      <Table className="non-fixed">
        <ListTableHead order={order} orderBy={orderBy} orderFn={orderFn} />
        <TableBody>
          <TableRows
            items={catalogues}
            // institutions={institutions}
            // persons={persons}
            orderBy={orderBy}
            order={order}
            goToUrl={goToUrl}
          />
        </TableBody>
      </Table>
    </div>
  )
}

const getCatalogues = (state) => {
  const catalogues = Object.values(state.catalogues.items)
  if (state.filters.visible) {
    //opposite fix
    return catalogues
  } else {
    return filterCatalogues(catalogues, state.filters.q)
  }
}

const filterCatalogues = (items, query) => {
  return items.filter((i) => {
    let searchableString = `${i.title_en}`
    searchableString = searchableString + i.title_nb
    searchableString = searchableString + i.artworks_id
    return searchableString.toLowerCase().includes(query.toLowerCase())
  })
}

const Catalogues = () => {
  const loading = useSelector((state) => state.catalogues.state)
  const error = useSelector((state) => state.catalogues.error)
  const watch = 'page'
  const dispatch = useDispatch()
  const load = () => {
    dispatch(loadCataloguesAll())
    //dispatch(loadPersons())
  }
  return (
    <DataProviderFunctional error={error} load={load} loading={loading} watch={watch} component={CataloguesPresenter} />
  )
}

export default Catalogues
