import * as React from 'react'
import { useSelector } from 'react-redux'
import { FieldArray } from 'redux-form'
import Modal from 'react-modal'
import IconButton from '@mui/material/IconButton'
import { Close } from '@mui/icons-material'
import OpenClose from '../general/OpenClose'
import { ReferencesForm } from './ReferenceField'
import OutlinedButton from '../general/OutlinedButton'
import { COLOR_LIGHT_TEXT } from '../../config/colors'

const Item = ({ onRemove, item }) => {
  const colorAccent = useSelector((state) => state.auth.colorAccent)
  return (
    (<div className="reference-item" style={{ color: colorAccent }}>
      <div>
        {item.author} ({item.year}, {item.page})
      </div>
      <IconButton onClick={onRemove} size="large">
        <Close style={{ color: COLOR_LIGHT_TEXT }} />
      </IconButton>
    </div>)
  );
}

const List = ({ fields }) => (
  <div>
    {fields.map((name, index) => (
      <Item key={`${name}-${index}`} name={name} onRemove={() => fields.remove(index)} item={fields.get(index)} />
    ))}
  </div>
)

export const ReferenceFieldList = (props) => (
  <OpenClose>
    {({ isOpen, open, close }) => (
      <div className="reference-list-wrapper">
        <FieldArray name={props.name} component={List} />
        <OutlinedButton color="secondary" onClick={open} label="Add new reference" />
        <Modal
          isOpen={isOpen}
          onRequestClose={close}
          className={{
            base: 'modal',
            afterOpen: 'modal-open reference-modal',
            beforeClose: 'modal-close',
          }}
          overlayClassName={{
            base: 'modal-overlay',
            afterOpen: 'modal-overlay-open',
            beforeClose: 'modal-overlay-close',
          }}
        >
          <ReferencesForm {...props} close={close} />
        </Modal>
      </div>
    )}
  </OpenClose>
)
