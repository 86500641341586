import React from 'react'
import * as Sentry from '@sentry/react'
import Auth from '../auth/Auth'
import ErrorBoundary from '../errorBoundary/ErrorBoundary'
import Header from '../header/Header'
import AuthProvider from '../auth/AuthProvider'
import { Redirect, Route, Switch } from 'react-router-dom'
import * as router from '../../services/router'
import Artworks from '../artworks/list/Artworks'
import Users from '../users/list/Users'
import ArtworkEdit from '../artworks/edit/ArtworkEdit'
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles'
import Menu from '../menu/Menu'
import UserEdit from '../users/edit/UserEdit'
import UserCreate from '../users/create/UserCreate'
import ArtworkCreate from '../artworks/create/ArtworkCreate'
import Logout from '../auth/Logout'
import Profile from '../profile/Profile'
import User from '../users/user/User'
import Exhibitions from '../exhibitions/list/Exhibitions'
import References from '../references/References'
import ExhibitionEdit from '../exhibitions/edit/ExhibitionEdit'
import ExhibitionCreate from '../exhibitions/create/ExhibitionCreate'
import Persons from '../persons/list/Persons'
import PersonCreate from '../persons/create/PersonCreate'
import PersonEdit from '../persons/edit/PersonEdit'
import ReferenceCreate from '../references/ReferenceCreate'
import ReferenceEdit from '../references/ReferenceEdit'
import Institutions from '../institutions/list/Institutions'
import { InstitutionEdit } from '../institutions/edit/InstitutionEdit'
import { InstitutionCreate } from '../institutions/create/InstitutionCreate'
import KeywordCreate from '../keywords/create/KeywordCreate'
import KeywordEdit from '../keywords/edit/KeywordEdit'
import Keywords from '../keywords/list/Keywords'
import DocumentCreate from '../documents/create/DocumentCreate'
import DocumentEdit from '../documents/edit/DocumentEdit'
import Documents from '../documents/list/Documents'
import CatalogueCreate from '../catalogues/create/CatalogueCreate'
import CatalogueEdit from '../catalogues/edit/CatalogueEdit'
import Catalogues from '../catalogues/list/Catalogues'
import { useSelector } from 'react-redux'
import { FocusListener } from '../general/FocusListener'
import {
  COLOR_DARK_TEXT,
  COLOR_ERROR,
  COLOR_GRAY_BCG,
  COLOR_GREY,
  COLOR_LIGHT_TEXT,
  COLOR_PRIMARY,
} from '../../config/colors'
import ForgotPassword from '../forgotPassword/ForgotPassword'
import ResetPassword from '../resetPassword/ResetPassword'

const artworksRedirect = () => <Redirect to={router.ROUTE_ARTWORKS} />

const App = () => {
  const colorAccent = useSelector((state) => state.auth.colorAccent)

  const theme = createTheme({
    palette: {
      primary: {
        main: colorAccent,
      },
      secondary: {
        main: COLOR_DARK_TEXT,
        light: COLOR_LIGHT_TEXT,
      },
      error: {
        main: COLOR_ERROR,
      },
      text: {
        primary: COLOR_LIGHT_TEXT,
        secondary: COLOR_DARK_TEXT,
      },
      background: {
        paper: COLOR_GRAY_BCG,
        default: COLOR_PRIMARY,
      },
      // Other palette properties
    },
    // Other theme properties
    // typography: {
    //   button: {
    //     fontSize: '14px',
    //   },
    // },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '& > *': {
              transition: 'opacity 0.3s',
            },
            '&:hover > *': {
              opacity: 0.8,
            },
          },
        },
      },
      MuiIconButton: {
        defaultProps: {
          disableRipple: true, // no ripple for IconButton
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '0 24px',
            height: '48px',
            lineHeight: 1.2,
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            letterSpacing: '0.033em',
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover',
        },
      },
      // Override styles for MuiCheckbox
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: colorAccent,
            padding: 2,
            marginRight: 16,
            '&:hover': {
              backgroundColor: 'transparent', // Prevent background change on hover
            },
            '&.Mui-checked': {
              color: colorAccent,
            },
            '&:hover > input': {
              opacity: 0,
            },
          },
        },
      },
      // Override styles for MuiChip
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: COLOR_GREY,
            '& .MuiChip-label': {
              color: COLOR_LIGHT_TEXT,
            },
          },
        },
      },
      // Override styles for MuiFormControlLabel (for checkboxes)
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            color: COLOR_LIGHT_TEXT,
            marginLeft: 0,
          },
        },
      },
    },
  })

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <AuthProvider loggedIn={<LoggedInContent />} loggedOut={<LoggedOutContent />} />
        </ErrorBoundary>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

const LoggedOutContent = () => (
  <Switch>
    <Route exact path="/" children={<Auth />} />
    <Route exact path={router.MATCH_FORGOT_PASSWORD} children={<ForgotPassword />} />
    <Route exact path={router.MATCH_PASSWORD_RESET} children={<ResetPassword />} />
    <Route exact path={router.MATCH_LOGIN} children={<Auth />} />
  </Switch>
)

const LoggedInContent = () => (
  <div className="full-height">
    <FocusListener />
    <Header />
    <div className="page-holder">
      <Menu />
      <div className="content-wrapper">
        <Switch>
          {/* <Route exact path={router.MATCH_ARTWORK_EDIT} component={ArtworkEdit} /> */}
          <Route exact path={router.MATCH_ARTWORK_EDIT} children={<ArtworkEdit />} />
          {/* <Route exact path={router.MATCH_ARTWORK_CREATE} component={ArtworkCreate} /> */}
          <Route exact path={router.MATCH_ARTWORK_CREATE} children={<ArtworkCreate />} />
          <Route exact path={router.MATCH_ARTWORKS} children={<Artworks />} />
          <Route exact path={router.MATCH_LOGIN} children={<Auth />} />
          <Route exact path={router.MATCH_LOGOUT} children={<Logout />} />
          <Route exact path={router.MATCH_USER_EDIT} children={<UserEdit />} />
          <Route exact path={router.MATCH_USER_CREATE} children={<UserCreate />} />
          <Route exact path={router.MATCH_USERS} children={<Users />} />
          <Route exact path={router.MATCH_PROFILE} children={<Profile />} />
          <Route exact path={router.MATCH_USER_PROFILE} children={<User />} />

          <Route exact path={router.MATCH_EXHIBITION_CREATE} children={<ExhibitionCreate />} />
          <Route exact path={router.MATCH_EXHIBITION_EDIT} children={<ExhibitionEdit />} />
          <Route exact path={router.MATCH_EXHIBITIONS} children={<Exhibitions />} />

          <Route exact path={router.MATCH_PERSON_CREATE} children={<PersonCreate />} />
          <Route exact path={router.MATCH_PERSON_EDIT} children={<PersonEdit />} />
          <Route exact path={router.MATCH_PERSONS} children={<Persons />} />

          <Route exact path={router.MATCH_INSTITUTION_CREATE} children={<InstitutionCreate />} />
          <Route exact path={router.MATCH_INSTITUTION_EDIT} children={<InstitutionEdit />} />
          <Route exact path={router.MATCH_INSTITUTIONS} children={<Institutions />} />

          <Route exact path={router.MATCH_REFERENCE_CREATE} children={<ReferenceCreate />} />
          <Route exact path={router.MATCH_REFERENCE_EDIT} children={<ReferenceEdit />} />
          <Route exact path={router.MATCH_REFERENCES} children={<References />} />

          <Route exact path={router.MATCH_KEYWORD_CREATE} children={<KeywordCreate />} />
          <Route exact path={router.MATCH_KEYWORD_EDIT} children={<KeywordEdit />} />
          <Route exact path={router.MATCH_KEYWORDS} children={<Keywords />} />

          <Route exact path={router.MATCH_DOCUMENT_CREATE} children={<DocumentCreate />} />
          <Route exact path={router.MATCH_DOCUMENT_EDIT} children={<DocumentEdit />} />
          <Route exact path={router.MATCH_DOCUMENTS} children={<Documents />} />

          <Route exact path={router.MATCH_CATALOGUE_CREATE} children={<CatalogueCreate />} />
          <Route exact path={router.MATCH_CATALOGUE_EDIT} children={<CatalogueEdit />} />
          <Route exact path={router.MATCH_CATALOGUES} children={<Catalogues />} />

          <Route exact path="/" render={artworksRedirect} />
        </Switch>
      </div>
    </div>
  </div>
)

export default Sentry.withProfiler(App)
