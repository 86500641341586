import React from 'react'
import { push } from 'connected-react-router'
import * as router from '../../../services/router'
import Pagination from '../../pagination/Pagination'
import { useDispatch, useSelector } from 'react-redux'
import { Top } from '../../header/Top'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import { CustomTableCell, makeSortableTableHeader } from '../../general/Table'
import { getOrder } from '../../../reducers/crud'
import { usersOrder } from '../../../actions/usersActions'
import { sortBy } from '../../../utils/utils'
import Loader from '../../loader/Loader'

const headers = [{ field: 'id', title: 'Id' }, { field: 'name', title: 'Name' }, { field: 'email', title: 'Email' }]
const ListTableHead = makeSortableTableHeader(headers)

const ListTableBody = ({ users, goToUrl, page, total, orderBy, order }) => {
  const itemsWithData = users
  sortBy(itemsWithData, orderBy, order)
  return itemsWithData.map((user, i) => (
    <TableRow key={i} onClick={() => goToUrl(router.ROUTE_USER_EDIT + user.id)}>
      <CustomTableCell>{user.id}</CustomTableCell>
      <CustomTableCell>{user.name}</CustomTableCell>
      <CustomTableCell>{user.email}</CustomTableCell>
    </TableRow>
  ))
}

const Users = () => {
  //users data is loaded on login, when AUTH_LOGGED_IN action is dispatched
    const users = useSelector(state => state.users.pages[state.users.page])
    const page = useSelector(state => state.users.page)
    const total = useSelector(state => state.users.total)
    const { orderBy, order } = useSelector(state => getOrder(state, 'users'))
    const dispatch = useDispatch()
    const goToUrl = url => dispatch(push(url))
    const orderFn = orderBy => () => dispatch(usersOrder(orderBy))

  return (
    <div>
      <Top title="Users" />
      <div className="content-inside">
        <div className="table-wrapper">
          {users ? (
            <div>
              <Table className="non-fixed">
                <ListTableHead orderBy={orderBy} order={order} orderFn={orderFn} />
                <TableBody>
                  <ListTableBody
                    users={users}
                    goToUrl={goToUrl}
                    page={page}
                    total={total}
                    orderBy={orderBy}
                    order={order}
                  />
                </TableBody>
              </Table>
              <Pagination currentPage={page} total={total} route={router.ROUTE_USERS} />
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  )
}

export default Users
