import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './TopSearch.css'
import { IconButton } from '@mui/material'
import { filterVisibilityToggle } from '../../actions/filtersActions'
import { Close, Search } from '@mui/icons-material'
import { COLOR_LIGHT_TEXT } from '../../config/colors'

export const TopSearch = ({ title, FiltersComponent, resultsRoute, resultsLoader, children, hideSearch }) => {
  const visible = useSelector((state) => state.filters.visible)
  const colorAccent = useSelector((state) => state.auth.colorAccent)
  const dispatch = useDispatch()

  const handleToggleFilter = (type, value) => {
    dispatch(filterVisibilityToggle())
  }

  return (
    (<div className="search">
      <div className="left-right-block flex-box-centered">
        <div className="title" style={{ color: colorAccent }}>
          <h3 className='title-heading'>{title}</h3>
        </div>
        {children}
        {hideSearch ? null : (
          <div className="search-section">
            <IconButton
              onClick={() => handleToggleFilter()}
              style={{ color: COLOR_LIGHT_TEXT }}
              size="large">
              {visible ? <Search /> : <Close />}
            </IconButton>
          </div>
        )}
      </div>
      <FiltersComponent resultsRoute={resultsRoute} resultsLoader={resultsLoader} />
    </div>)
  );
}
