import React, { useState } from 'react'
import { EditorState, Modifier } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Modal from 'react-modal'
import Select from 'react-select'
import { modalStyle } from '../form/style'
import TextButton from '../general/TextButton'
import { CustomDropdownIndicator } from '../general/CustomDropdownIndicator'

export const InsertMention = (props) => {
  const { editorState, onChange, items, title, labelKey } = props
  const [selectedItem, setSelectedItem] = useState(false)
  const contentState = editorState.getCurrentContent()
  const selection = editorState.getSelection()

  const insertCustomMention = () => {
    const contentStateWithEntity = contentState.createEntity('MENTION', 'IMMUTABLE', selectedItem)
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    const textWithEntity = Modifier.applyEntity(contentState, selection, entityKey)
    let newEditorState = EditorState.push(editorState, textWithEntity, 'apply-entity')
    newEditorState = EditorState.moveFocusToEnd(newEditorState)
    newEditorState = EditorState.forceSelection(newEditorState, textWithEntity.getSelectionAfter())
    onChange(newEditorState)
  }

  return (
    <div className="editor__custom-options rdw-inline-wrapper">
      <InsertModal
        insertFunction={insertCustomMention}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        title={title}
        items={items}
        labelKey={labelKey}
      />
    </div>
  )
}

export const InsertModal = (props) => {
  const { insertFunction, selectedItem, setSelectedItem, title, items, labelKey } = props
  const [open, setOpen] = useState(false)
  return (
    <div>
      <div className="editor__btn--insert-person rdw-option-wrapper" onClick={() => setOpen(true)}>
        {title}
      </div>
      <Modal isOpen={open} onRequestClose={() => setOpen(false)} {...modalStyle}>
        <div className="insert-mention-modal">
          <div className="id-line">
            <div>
              <div className="field-label">{`Select ${title} for the selected text`}</div>
              {items[0] && items[0].type === 'artwork' ? (
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="select"
                  options={items}
                  getOptionLabel={(option) => option[labelKey]}
                  getOptionValue={(option) => option.id}
                  value={selectedItem}
                  onChange={(value) => setSelectedItem(value)}
                  isClearable={false}
                  isSearchable={true}
                  components={{
                    DropdownIndicator: CustomDropdownIndicator,
                    Option: ImageOption,
                    SingleValue: ImageValue,
                  }}
                />
              ) : (
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  name="select"
                  options={items}
                  getOptionLabel={(option) => option[labelKey]}
                  getOptionValue={(option) => option.id}
                  value={selectedItem}
                  onChange={(value) => setSelectedItem(value)}
                  isClearable={false}
                  isSearchable={true}
                  components={{ DropdownIndicator: CustomDropdownIndicator }}
                />
              )}
            </div>
          </div>
          <div className="right-controls">
            <TextButton
              color="primary"
              label="Done"
              onClick={() => setOpen(false)}
              onMouseDown={insertFunction}
              type="button"
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}

const ImageOption = (props) => {
  const { data, innerRef, innerProps, className } = props
  const { image, title } = data

  return (
    <div
      ref={innerRef}
      {...innerProps}
      className="react-select__option react-select__option--image"
      title={title || 'Untitled'}
    >
      {image && <img src={image} className="select__option-image" alt={title} />}
      <span>{title || 'Untitled'}</span>
    </div>
  )
}

const ImageValue = (props) => {
  const { data } = props
  const { image, title } = data

  return (
    <div className="react-select__single-value react-select__single-value--image " title={title || 'Untitled'}>
      {image && <img src={image} className="select_value_image" alt={title} />}
      <span>{title || 'Untitled'}</span>
    </div>
  )
}
