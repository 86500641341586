import * as actionTypes from '../actions/actionTypes'
import { crudInitialState, itemsLoad, itemsLoadedAll, itemsLoadFailed } from './crud'

const initialState = crudInitialState

const actionHandlers = {
  [actionTypes.PROFESSIONS_LOAD]: itemsLoad,
  [actionTypes.PROFESSIONS_LOADED]: itemsLoadedAll,
  [actionTypes.PROFESSIONS_LOAD_FAILED]: itemsLoadFailed,
}

export default (state = initialState, { type, payload }) => {
  const handler = actionHandlers[type]
  if (handler && typeof handler === 'function') {
    return handler(state, payload)
  }
  return state
}
