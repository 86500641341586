import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import { useTheme } from '@mui/material'
import { push } from 'connected-react-router'
import * as router from '../../../services/router'
import { exhibitionsOrder, loadExhibitionsBasic } from '../../../actions/exhibitionsActions'
import { loadPersons } from '../../../actions/personsActions'
import { TopSearch } from '../../header/TopSearch'
import { dateByAccuracy, sortBy } from '../../../utils/utils'
import Filters from './Filters'
import { CustomTableCell, makeSortableTableHeader } from '../../general/Table'
import { getOrder } from '../../../reducers/crud'
import { Add } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import DataProviderFunctional from '../../loader/DataProviderFunctional'
import TextButton from '../../general/TextButton'

const headers = [
  { field: 'title', title: 'Title' },
  { field: 'gallery_name', title: 'Institution' },
  { field: 'from_year', title: 'Date from' },
  { field: 'to_year', title: 'Date to' },
  { field: 'location', title: 'Location' },
  { field: 'currator', title: 'Currator' },
  // { field: 'artworks', title: 'Number of artworks' },
  { field: 'is_separate', title: 'Separate/Group' },
]

const ListTableHead = makeSortableTableHeader(headers)

const TableRows = ({ items, persons, goToUrl, orderBy, order }) => {
  const itemsWithData = items.map((item) => {
    let isSeparate = item.is_separate ? 'Separate' : 'Group'

    let galleryName = item.institution ? item.institution.name : ''

    let currator = ''
    if (item.currators[0] && persons[item.currators[0].id]) {
      currator = persons[item.currators[0].id].firstname + ' ' + persons[item.currators[0].id].lastname
    }

    return {
      ...item,
      is_separate: isSeparate,
      gallery_name: galleryName,
      currator: currator,
    }
  })

  sortBy(itemsWithData, orderBy, order) // mutations :(

  return itemsWithData.map((item) => {
    return (
      <TableRow key={item.id} onClick={() => goToUrl(router.ROUTE_EXHIBITION_EDIT + item.id)}>
        <CustomTableCell>{item.title}</CustomTableCell>
        <CustomTableCell>{item.gallery_name}</CustomTableCell>
        <CustomTableCell>
          {dateByAccuracy(item.from_accuracy, item.from_year, item.from_month, item.from_day)}
        </CustomTableCell>
        <CustomTableCell>{dateByAccuracy(item.to_accuracy, item.to_year, item.to_month, item.to_day)}</CustomTableCell>
        <CustomTableCell>{item.location}</CustomTableCell>
        <CustomTableCell>{item.currator}</CustomTableCell>
        {/* <CustomTableCell>{item.artworks.length}</CustomTableCell> */}
        <CustomTableCell>{item.is_separate}</CustomTableCell>
      </TableRow>
    )
  })
}

const ExhibitionsPresenter = () => {
  const theme = useTheme()
  const exhibitions = useSelector((state) => getExhibitions(state))
  const persons = useSelector((state) => state.persons.items)
  const institutions = useSelector((state) => state.institutions.items)
  const { order, orderBy } = useSelector((state) => getOrder(state, 'exhibitions'))
  const dispatch = useDispatch()
  const goToUrl = (url) => dispatch(push(url))
  const orderFn = (orderBy) => () => dispatch(exhibitionsOrder(orderBy))

  return (
    <div className="exhibitions">
      <TopSearch title="Exhibitions" FiltersComponent={Filters}>
        <div className="item-right">
          <TextButton
            label="Add exhibition"
            color="primary"
            startIcon={<Add  style={{ color: theme.palette.primary.main }} />}
            onClick={() => goToUrl(router.ROUTE_EXHIBITION_CREATE)}
          />
        </div>
      </TopSearch>

      <Table className="non-fixed">
        <ListTableHead order={order} orderBy={orderBy} orderFn={orderFn} />
        <TableBody>
          <TableRows
            items={exhibitions}
            institutions={institutions}
            persons={persons}
            orderBy={orderBy}
            order={order}
            goToUrl={goToUrl}
          />
        </TableBody>
      </Table>
    </div>
  )
}

const getExhibitions = (state) => {
  const exhibitions = Object.values(state.exhibitions.items)
  if (state.filters.visible) {
    //opposite fix
    return exhibitions
  } else {
    return filterExhibitions(exhibitions, state.filters.q)
  }
}

const filterExhibitions = (items, query) => {
  return items.filter((e) => (e.title ? e.title.toLowerCase().includes(query.toLowerCase()) : null))
  // return items.filter(e => {
  //   let searchString = ''
  //   e.titles.forEach(title => {
  //     for (let lang in title.translations) {
  //       let titleString = title.translations[lang].title
  //       if (titleString) searchString += titleString + ' '
  //     }
  //   })
  //
  //   return searchString.toLowerCase().includes(query.toLowerCase())
  // })
}

const Exhibitions = () => {
  const loading = useSelector((state) => state.exhibitions.state)
  const error = useSelector((state) => state.exhibitions.error)
  const watch = 'page'
  const dispatch = useDispatch()
  const load = () => {
    //dispatch(loadExhibitions())
    dispatch(loadExhibitionsBasic())
    dispatch(loadPersons())
  }
  return (
    <DataProviderFunctional
      error={error}
      load={load}
      loading={loading}
      watch={watch}
      component={ExhibitionsPresenter}
    />
  )
}

export default Exhibitions
