import * as actionTypes from '../actions/actionTypes'
import {
  crudInitialState,
  itemLoad,
  itemLoaded,
  itemsLoadedAll,
  itemLoadFailed,
  itemSave,
  itemSavedCache,
  itemSaveFailed,
  itemsLoad,
  itemsLoaded,
  itemsLoadFailed,
  itemsChangeSortOrder,
  itemDelete,
  itemDeleted,
  itemDeleteFailed,
  itemsOrder,
} from './crud'

const initialState = crudInitialState

const actionHandlers = {
  [actionTypes.PROVENANCE_INSTITUTIONS_LOAD]: itemsLoad,
  [actionTypes.PROVENANCE_INSTITUTIONS_LOADED]: itemsLoaded,
  [actionTypes.PROVENANCE_INSTITUTIONS_LOADED_ALL]: itemsLoadedAll,
  [actionTypes.PROVENANCE_INSTITUTIONS_LOAD_FAILED]: itemsLoadFailed,
  [actionTypes.PROVENANCE_INSTITUTION_LOAD]: itemLoad,
  [actionTypes.PROVENANCE_INSTITUTION_LOADED]: itemLoaded,
  [actionTypes.PROVENANCE_INSTITUTION_LOAD_FAILED]: itemLoadFailed,
  [actionTypes.PROVENANCE_INSTITUTION_UPDATE]: itemSave,
  [actionTypes.PROVENANCE_INSTITUTION_CREATE]: itemSave,
  [actionTypes.PROVENANCE_INSTITUTION_UPDATED]: itemSavedCache,
  [actionTypes.PROVENANCE_INSTITUTION_CREATED]: itemSavedCache,
  [actionTypes.PROVENANCE_INSTITUTION_UPDATE_FAILED]: itemSaveFailed,
  [actionTypes.PROVENANCE_INSTITUTION_CREATE_FAILED]: itemSaveFailed,
  [actionTypes.PROVENANCE_INSTITUTIONS_CHANGE_SORT_ORDER]: itemsChangeSortOrder,
  [actionTypes.PROVENANCE_INSTITUTIONS_ORDER]: itemsOrder,
  [actionTypes.PROVENANCE_INSTITUTION_DELETE]: itemDelete,
  [actionTypes.PROVENANCE_INSTITUTION_DELETED]: itemDeleted,
  [actionTypes.PROVENANCE_INSTITUTION_DELETE_FAILED]: itemDeleteFailed,
}

export default (state = initialState, { type, payload }) => {
  const handler = actionHandlers[type]
  return handler ? handler(state, payload) : state
}
