import * as React from 'react'
import { Field, FieldArray } from 'redux-form'
import { Tooltip, IconButton } from '@mui/material'
import { ReferenceField } from '../../form/ReferenceField'
import { Close } from '@mui/icons-material'
import { PERSON_FORM } from '../../../services/forms'
import OutlinedButton from '../../general/OutlinedButton'
import { COLOR_LIGHT_TEXT } from '../../../config/colors'
import { CustomTextField } from '../../general/CustomTextField'

const AlternativeNameComponent = ({ index, fields, member }) => (
  <div key={index} className="artwork-row title-row">
    <div className="flex-box">
      <div className="repeater-field">
        <Field id={`${member}.name-input`} name={`${member}.name`} component={CustomTextField} type="text" label="Alternative name" hideLabel={true}/>
      </div>
      <div className="repeater-field">
        <div className="flex-box-centered">
          <ReferenceField name={`references.alternative_name_${index}`} formType={PERSON_FORM} />
          <Tooltip title="Remove">
            <IconButton
              className="repeater-remove"
              onClick={() => fields.remove(index)}
              size="large">
              <Close style={{ color: COLOR_LIGHT_TEXT }} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  </div>
)

const PersonAlternativeNamesField = ({ fields }) => {
  return (
    <div>
      {fields.map((member, index, fields) => {
        return <AlternativeNameComponent key={index} member={member} index={index} fields={fields} />
      })}
      <OutlinedButton color="secondary" onClick={() => fields.push({ name: '' })} label="Add new alternative name" />
    </div>
  )
}

const PersonAlternativeNames = () => (
  <div>
    <FieldArray name="alternative_names" component={PersonAlternativeNamesField} />
  </div>
)

export default PersonAlternativeNames
