import * as actionTypes from '../actions/actionTypes'
import {
  crudInitialState,
  itemDelete,
  itemDeleted,
  itemDeleteFailed,
  itemLoad,
  itemLoaded,
  itemLoadFailed,
  itemSave,
  itemSaved,
  itemSaveFailed,
  itemsLoad,
  itemsLoaded,
  itemsLoadFailed,
  itemsOrder,
  itemsSearch,
  itemsSearched,
  itemsSearchFailed,
  referenceError,
  removeReferenceError,
} from './crud'

const initialState = crudInitialState

const actionHandlers = {
  [actionTypes.REFERENCES_LOAD]: itemsLoad,
  [actionTypes.REFERENCES_LOADED]: itemsLoaded,
  [actionTypes.REFERENCES_LOAD_FAILED]: itemsLoadFailed,
  [actionTypes.REFERENCES_SEARCH]: itemsSearch,
  [actionTypes.REFERENCES_ERROR]: referenceError,
  [actionTypes.REFERENCES_REMOVE_ERROR]: removeReferenceError,
  [actionTypes.REFERENCES_SEARCHED]: itemsSearched,
  [actionTypes.REFERENCES_SEARCH_FAILED]: itemsSearchFailed,
  [actionTypes.REFERENCE_LOAD]: itemLoad,
  [actionTypes.REFERENCE_LOADED]: itemLoaded,
  [actionTypes.REFERENCE_LOAD_FAILED]: itemLoadFailed,
  [actionTypes.REFERENCE_UPDATE]: itemSave,
  [actionTypes.REFERENCE_CREATE]: itemSave,
  [actionTypes.REFERENCE_UPDATED]: itemSaved,
  [actionTypes.REFERENCE_CREATED]: itemSaved,
  [actionTypes.REFERENCE_UPDATE_FAILED]: itemSaveFailed,
  [actionTypes.REFERENCE_CREATE_FAILED]: itemSaveFailed,
  [actionTypes.REFERENCES_ORDER]: itemsOrder,
  [actionTypes.REFERENCE_DELETE]: itemDelete,
  [actionTypes.REFERENCE_DELETED]: itemDeleted,
  [actionTypes.REFERENCE_DELETE_FAILED]: itemDeleteFailed,
}

export default (state = initialState, { type, payload }) => {
  const handler = actionHandlers[type]
  return handler ? handler(state, payload) : state
}
