import React, { useState, useRef } from 'react'
import { useDropzone } from 'react-dropzone'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { Modal, Button, Typography } from '@mui/material'

// Custom hook to handle upload logic
const useUploader = ({ upload, onStart, onSuccess, onError }) => {
  const [uploading, setUploading] = useState(false)

  const handleDrop = (files) => {
    setUploading(true)
    onStart()
    upload(files[0]).subscribe({
      next: (file) => {
        setUploading(false)
        onSuccess(file)
      },
      error: (error) => {
        setUploading(false)
        onError(error)
      },
    })
  }

  return { uploading, handleDrop }
}

// AvatarUploader Component
export const AvatarUploader = ({ upload, onStart, onSuccess, onError, loader: Loader, image }) => {
  const [uploading, setUploading] = useState(false)

  const onDrop = (files) => {
    setUploading(true)
    onStart()
    upload(files[0]).subscribe({
      next: (file) => {
        setUploading(false)
        onSuccess(file)
      },
      error: (error) => {
        setUploading(false)
        onError(error)
      },
    })
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return uploading && Loader ? (
    <Loader />
  ) : (
    <div {...getRootProps()} className="dropzone">
      <input {...getInputProps()} />
      {image ? (
        <img src={image} alt="avatar" />
      ) : (
        <p>Try dropping some files here, or click to select files to upload.</p>
      )}
    </div>
  )
}

// Uploader Component
export const Uploader = ({ upload, onStart, onSuccess, onError, loader: Loader, image }) => {
  const { uploading, handleDrop } = useUploader({ upload, onStart, onSuccess, onError })

  const { getRootProps, getInputProps } = useDropzone({ onDrop: handleDrop })

  return uploading && Loader ? (
    <Loader />
  ) : (
    <div {...getRootProps()} className="dropzone">
      <input {...getInputProps()} />
      {image ? (
        <img src={image} alt="avatar" />
      ) : (
        <p>Try dropping some files here, or click to select files to upload.</p>
      )}
    </div>
  )
}

// ReUploader Component
export const ReUploader = ({ upload, onStart, onSuccess, onError, loader: Loader }) => {
  const { uploading, handleDrop } = useUploader({ upload, onStart, onSuccess, onError })
  const { getRootProps, getInputProps } = useDropzone({ onDrop: handleDrop })

  return uploading && Loader ? (
    <Loader />
  ) : (
    <div {...getRootProps()} className="reDropzone">
      <input {...getInputProps()} />
      <Typography color="secondary">REPLACE</Typography>
    </div>
  )
}

// CropUploader Component
export const CropUploader = ({ upload, onStart, onSuccess, onError, loader: Loader }) => {
  const [uploading, setUploading] = useState(false)
  const [image, setImage] = useState(null)
  const [cropperOpen, setCropperOpen] = useState(false)
  const cropperRef = useRef(null)

  const handleDrop = (files) => {
    setImage(URL.createObjectURL(files[0]))
    setCropperOpen(true)
  }

  const handleClose = () => setCropperOpen(false)

  const dataURLtoFile = (base64, filename) => {
    const arr = base64.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }

  const uploadPhoto = () => {
    setUploading(true)
    setCropperOpen(false)
    onStart()

    const base64 = cropperRef.current.getCroppedCanvas().toDataURL('image/jpeg', 0.9)
    const file = dataURLtoFile(base64, 'cropped-image.jpg')

    upload(file).subscribe({
      next: (uploadedFile) => {
        setUploading(false)
        onSuccess(uploadedFile)
      },
      error: (error) => {
        setUploading(false)
        onError(error)
      },
    })
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop: handleDrop })

  return uploading && Loader ? (
    <Loader />
  ) : (
    <div>
      <div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />
        <p>Try dropping some files here, or click to select files to upload.</p>
      </div>
      {image && (
        <Modal
          open={cropperOpen}
          onClose={handleClose}
          className="uploader-modal"
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            height: 600,
            background: '#333',
          }}
        >
          <div>
            <Cropper
              ref={cropperRef}
              src={image}
              style={{ height: 500, width: 500 }}
              aspectRatio={1}
              autoCropArea={1}
              guides={false}
            />
            <div style={{ textAlign: 'right', padding: 10 }}>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={uploadPhoto} variant="contained" color="primary">
                Done
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default Uploader
