//
import React from 'react'
import * as config from '../../config/config'
import { range } from '../../utils/utils'
import { Link } from 'react-router-dom'
import { IconButton } from '@mui/material'
import './Pagination.css'
import { useSelector } from 'react-redux'
import { COLOR_BLACK_LIGHT, COLOR_LIGHT_TEXT } from '../../config/colors'
import { NavigateBefore, NavigateNext } from '@mui/icons-material'

const SHOW_PAGES = 2
const FIRST_PAGE = 1

const Pagination = ({ currentPage, total, route }) => {
  const colorAccent = useSelector(state => state.auth.colorAccent)

  const last = parseInt(Math.ceil(total / config.ITEMS_PER_PAGE),10)
  const isFirst = currentPage === FIRST_PAGE
  const isLast = currentPage === last
  const prev = currentPage - 1
  const next = currentPage + 1
  const start = currentPage - SHOW_PAGES < FIRST_PAGE ? FIRST_PAGE : currentPage - SHOW_PAGES
  const end = currentPage + SHOW_PAGES > last ? last : currentPage + SHOW_PAGES
  const pages = range(start, end + 1)
  if (pages < 2) {
    return null
  }
  return (
    (<div className="pagination flex-box-centered">
      {isFirst === false ? (
        <Link to={route + prev}>
          <IconButton style={{ color: COLOR_LIGHT_TEXT}} size="large">
            <NavigateBefore />
          </IconButton>
        </Link>
      ) : null}
      {pages.map((page, i) => (
        <Link key={i} to={route + page}>
          <button
            className={page === currentPage ? 'page-button current' : 'page-button'}
            style={{ background: page === currentPage ?  colorAccent : COLOR_BLACK_LIGHT }}
            disabled={page === currentPage}
          >
            {page}
          </button>
        </Link>
      ))}
      {isLast === false ? (
        <Link to={route + next}>
          <IconButton style={{ color: COLOR_LIGHT_TEXT}} size="large">
            <NavigateNext />
          </IconButton>
        </Link>
      ) : null}
    </div>)
  );
}

export default Pagination
