import React from 'react'
import { Checkbox, FormControlLabel } from '@mui/material'

export const CustomCheckbox = ({ input, label }) => {
  return (
    <FormControlLabel
      label={label}
      control={
        <Checkbox
        //   name={input.name}
          checked={input.value ? true : false}
          onChange={input.onChange}
        />
      }
    />
  )
}
