//
import React from 'react'
import { CircularProgress } from '@mui/material'

const Loader = () => {
  return (
    <div className="loading-holder">
      <CircularProgress size={50} color="primary" />
    </div>
  )
}

export default Loader
