//
import { Field, change, reduxForm } from 'redux-form'
import * as React from 'react'
import Select from 'react-select'
import { AUTH_FORM } from '../../services/forms'
import './Auth.css'
import { DATABASES } from '../../config/config'
import { useDispatch } from 'react-redux'
import TextButton from '../general/TextButton'
import { CustomTextField } from '../general/CustomTextField'
import { CustomDropdownIndicator } from '../general/CustomDropdownIndicator'

const wrapperStyle = {
  width: '',
}

const validate = (values) => {
  const errors = {}
  if (!values.username) {
    errors.username = 'Please enter your email'
  }
  if (!values.password) {
    errors.password = 'Please enter your password'
  }
  if (!values.database) {
    errors.database = 'Please select a database'
  }
  return errors
}

const AuthFormPresentation = ({ handleSubmit, loading, handleColorChange }) => {
  const dispatch = useDispatch()
  const changeFieldValue = (field, value) => {
    dispatch(change(AUTH_FORM, field, value))
  }

  return (
    <div>
      <div className="login-form">
        <form onSubmit={handleSubmit} className="shadow">
          <div className="field-spacing">
            <Field id="username-input" name="username" type="email" component={CustomTextField} label="Email" />
          </div>
          <div className="field-spacing">
            <Field id="password-input" name="password" type="password" component={CustomTextField} label="Password" />
          </div>
          <div className="field-spacing">
            <label className="field-label auth-label" htmlFor="database-input">
              Database
            </label>
            <Field
              id="database-input"
              className="text-input"
              name="database"
              component={({ input, meta }) => (
                <>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    name={input.name}
                    options={DATABASES}
                    getOptionLabel={(option) => option.title}
                    getOptionValue={(option) => option.id}
                    value={input.value ? DATABASES.find((db) => db.id === input.value) : null}
                    onChange={(value) => {
                      handleColorChange(value)
                      changeFieldValue(input.name, value?.id || undefined)
                    }}
                    isClearable={false}
                    isSearchable={true}
                    components={{ DropdownIndicator: CustomDropdownIndicator }}
                  />
                  {meta.touched && meta.error && <span className="field-error">{meta.error}</span>}
                </>
              )}
            />
          </div>
          <div className="centered login-button">
            <TextButton type="submit" label={loading ? 'Signing in...' : 'Sign in'} fullWidth={true} color="primary" />
          </div>
        </form>
      </div>
    </div>
  )
}

const formProps = {
  form: AUTH_FORM,
  validate,
}
const AuthForm = reduxForm(formProps)(AuthFormPresentation)

export default AuthForm
