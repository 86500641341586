import * as React from 'react'
import { Field, FieldArray } from 'redux-form'
import Repeater from './Repeater'
import { ReferenceField } from './ReferenceField'
import { Close } from '@mui/icons-material'
import { commentary } from '../../models/comentaryModel'
import { ARTWORK_FORM } from '../../services/forms'
import { Tooltip, IconButton } from '@mui/material'
import { COLOR_LIGHT_TEXT } from '../../config/colors'
import { CustomTextField } from '../general/CustomTextField'

const TechnicalCommentariesField = ({ member, index, fields, lang }) => (
  <div key={index} className="artwork-row commentary-row">
    <div className="flex-box flex-box-centered full">
      <div className="repeater-field full-field">
        <Field
          id={`${member}.translations.${lang}.text-input`}
          name={`${member}.translations.${lang}.text`}
          label="Commentary"
          hideLabel={true}
          width="full"
          component={CustomTextField}
          type="text"
        />
      </div>
      <ReferenceField name={`references.tech_commentaries_${index}`} formType={ARTWORK_FORM} />
      <Tooltip title="Remove">
        <IconButton
          className="repeater-remove"
          onClick={() => fields.remove(index)}
          size="large">
          <Close style={{ color: COLOR_LIGHT_TEXT}} />
        </IconButton>
      </Tooltip>
    </div>
  </div>
)

const TechnicalCommentariesFields = Repeater(
  TechnicalCommentariesField,
  (fields) => fields.push(commentary),
  'Add new technical commentary'
)

const TechnicalCommentaries = ({ lang }) => (
  <div>
    <FieldArray name="tech_commentaries" component={TechnicalCommentariesFields} lang={lang} />
  </div>
)

export default TechnicalCommentaries
