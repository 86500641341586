import React from 'react'
import { TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { textFieldStyle } from '../form/style'
import { COLOR_BLACK, COLOR_GRAY_DARK, COLOR_WHITE } from '../../config/colors'

export const useStyles = makeStyles({
  root: {
    ...textFieldStyle.style,
    width: 256,
  },
  input: {
    ...textFieldStyle.inputStyle,
  },
  underline: {
    ...textFieldStyle.underlineStyle,
    '&:before': {
      borderBottom: `3px solid transparent`,
    },
    '&&:hover:not($disabled):before': {
      borderBottom: `3px solid transparent`,
    },
    '&:after': {
      borderBottomWidth: `3px`,
    },
    '&.Mui-error:after': {
      borderBottom: `3px solid transparent`,
    },
  },
  fullWidth: {
    width: '100%',
  },
  shortWidth: {
    width: '100px',
  },
  light: {
    background: COLOR_WHITE,
    maxWidth: '100%',
    border: `1px solid ${COLOR_GRAY_DARK}`,
    margin: '0px',
    '& .MuiInputBase-input': {
      color: COLOR_BLACK,
    },
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
})

export const CustomTextField = ({
  id,
  name,
  label,
  hideLabel,
  value,
  onChange,
  width,
  light,
  input,
  disabled,
  meta,
  ...rest
}) => {
  const classes = useStyles()
  const isError = meta && meta.error && meta.touched
  return (
    <>
      {label && !hideLabel && (
        <label className="field-label" htmlFor={id}>
          {label}
        </label>
      )}
      <TextField
        id={id}
        name={name}
        variant="standard"
        InputProps={{
          classes: {
            input: classes.input,
            underline: classes.underline,
          },
        }}
        inputProps={{ 'aria-label': `${label}` }}
        classes={{
          root: `${classes.root} ${light ? classes.light : ''} ${
            width === 'full' ? classes.fullWidth : width === 'short' ? classes.shortWidth : ''
          } ${disabled ? classes.disabled : ''}`,
        }}
        value={value}
        onChange={(event) => onChange(name, event.target.value, event)}
        disabled={disabled}
        error={isError}
        helperText={meta && meta.touched && meta.error}
        {...input}
        {...rest}
      />
    </>
  )
}
