import React from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { KEYWORD_FORM } from '../../../services/forms'
import { deleteKeyword, loadKeyword, updateKeyword } from '../../../actions/keywordsActions'
import KeywordForm from '../form/KeywordForm'
import { change, formValueSelector, submit } from 'redux-form'
import FormTop from '../../form/FormTop'
import { uploadOwnerPhoto } from '../../../services/http'
import { keywordModel } from '../../../models/keywordModel'
import { EditTop } from '../../general/EditTop'
import DataProviderFunctional from '../../loader/DataProviderFunctional'

const KeywordEditPresentation = () => {
  let { id } = useParams()
  const lang = useSelector((state) => state.translations.language)
  const keywords = useSelector((state) => state.keywords.items)
  const initialValues = keywords[id] ? keywords[id] : keywordModel
  const saveState = useSelector((state) => state.keywords.saveState)
  const selector = formValueSelector(KEYWORD_FORM)
  const category = useSelector((state) => selector(state, 'category'))
  const dispatch = useDispatch()
  const onSubmit = (data) => dispatch(updateKeyword({ ...data, id: id }))
  const remoteSubmit = () => dispatch(submit(KEYWORD_FORM))
  const remove = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteKeyword(id))
    }
  }
  const changeFieldValue = (field, value) => dispatch(change(KEYWORD_FORM, field, value))
  const upload = uploadOwnerPhoto

  return (
    <div>
      <div className="Exhibition-controls">
        <EditTop title="Edit Keyword" remove={remove} />
        <div className="controls">
          <FormTop title={initialValues.keyword} button="Save Keyword" remote={remoteSubmit} remove={remove} saveState={saveState} />
          <div className="row flex-box">
          </div>
        </div>
      </div>

      <div className="Exhibition-grid">
        {/* <NavigationPrompt when={dirty} /> */}
        <KeywordForm
          lang={lang}
          onSubmit={onSubmit}
          changeFieldValue={changeFieldValue}
          initialValues={initialValues}
          upload={upload}
          category={category}
        />
      </div>

      <div />
    </div>
  )
}

const KeywordEdit = () => {
  let { id } = useParams()
  const error = useSelector((state) => state.keywords.error)
  const loading = useSelector((state) => state.keywords.state)
  const dispatch = useDispatch()
  const load = () => {
    dispatch(loadKeyword(id))
  }

  return (
    <DataProviderFunctional
      error={error}
      load={load}
      loading={loading}
      component={KeywordEditPresentation}
    />
  )
}

export default KeywordEdit
